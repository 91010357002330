import * as React from 'react'
import api from '~/api/endpoints'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'
import {TRegisterPersonParams} from '~/api/generated/types/RegisterPerson'
import {useAuth} from '~/hooks/useAuth'
import WeatherUI from '~/pages/Weather/stateless'
import {setDashboardData} from '~/redux/reducers/weather'
import store from '~/redux/store'

interface IProps {}

interface IState {}

export default function WeatherPage() {
  const receiveWeatherDashboardData = (dashboardData: TPeopleDataResponse) => {
    store.dispatch(setDashboardData(dashboardData))
  }
  const user = useAuth()

  return (
    <api.weather.people.PromiseManager
      params={{}}
      pathPieces={{}}
      onSuccess={receiveWeatherDashboardData}
      then={() => (
        <WeatherUI
          user={user}
          registerNewPerson={params => api.weather.register.post(params, {})}
          deletePerson={(personId: string) =>
            api.weather.deletePerson.post({}, {personId})
          }
          editPersonDetails={(params: TRegisterPersonParams, personId: string) =>
            api.weather.editDetails.post(params, {personId})
          }
        />
      )}
    />
  )
}
