import * as React from 'react'
import DSLink from '~/design-system/Link'
import User from '~/models/app/User'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import {DSTextDiv} from '~/design-system/DSText'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps {
  dashboardData: TDashboardDataResponse
  user: User
}

interface IState {}

export default class PlantsUI extends React.Component<IProps, IState> {
  renderCard(title: string, link: string) {
    return (
      <DSLink underline="never" className={styles.link} to={link}>
        <DSTextDiv size="body-15" className={styles.card}>
          {title}
        </DSTextDiv>
      </DSLink>
    )
  }

  render() {
    return (
      <div className={styles.plantsWrapper}>
        <DSTextDiv size="h1" className={styles.header}>
          Plants!
        </DSTextDiv>

        <DSTextDiv size="body-17" className={styles.inspiration}>
          Because every gardener deserves high quality planning tools.
        </DSTextDiv>
      </div>
    )
  }
}
