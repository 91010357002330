import * as React from 'react'
import styles from './styles.module.css'
import {TPersonDataResponse} from '~/api/generated/types/common'
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'
import {TRegisterPersonParams} from '~/api/generated/types/RegisterPerson'
import DSModalForm from '~/design-system/Modals/Form'
import ProvideForm from '~/components/ProvideForm/normal'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import {notEmpty} from '~/components/ProvideForm/utils'
import Spacer from '~/components/Spacer'
import DSButton from '~/design-system/Button'
import {setDashboardData} from '~/redux/reducers/weather'
import store from '~/redux/store'

interface IProps {
  row: TPersonDataResponse
  deletePerson: (personId: string) => Promise<TPeopleDataResponse>
  editPersonDetails: (
    registerPersonParams: TRegisterPersonParams,
    personId: string
  ) => Promise<TPeopleDataResponse>
}

interface IState {
  anchorEl: HTMLElement | null
  editModal: boolean
}

export default class WeatherRenderRow extends React.Component<IProps, IState> {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  state = {
    anchorEl: null,
    editModal: false,
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorEl: event.currentTarget})
  }
  handleClose = () => {
    this.setState({anchorEl: null, editModal: false})
  }

  renderEditModal = () => {
    return (
      <DSModalForm
        title="Edit Details"
        showCloseIcon={true}
        onRequestClose={() => this.setState({editModal: false})}
        over300PxTall={false}
      >
        <ProvideForm<TRegisterPersonParams, TPeopleDataResponse>
          onSubmit={(formFields: TRegisterPersonParams) =>
            this.props.editPersonDetails(formFields, this.props.row.id)
          }
          wrapperClassName={styles.formWrapper}
          submitSuccess={(_, r) => {
            this.setState({editModal: false})
            store.dispatch(setDashboardData(r))
          }}
          toFormFields={(generateFormFieldProps, formProps) => {
            return (
              <div>
                <LabeledLineTextInput
                  label="Name"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps('name', [notEmpty], {
                    initialValue: this.props.row.name,
                  })}
                  autoFocus={true}
                />

                <Spacer height="40" />

                <DSButton
                  buttonStyle="primary"
                  size="large"
                  onClick={() => formProps.onSubmit()}
                  loading={formProps.isFormSubmitting}
                  disabled={formProps.isFormSubmitting}
                  appearDisabled={formProps.anyErrors}
                >
                  Edit
                </DSButton>
              </div>
            )
          }}
        />
      </DSModalForm>
    )
  }

  render() {
    return (
      <TableRow className={styles.weatherTableRow}>
        <TableCell className={styles.weatherTableData}>
          <Typography variant="body1">{this.props.row.name}</Typography>
        </TableCell>
        <TableCell className={styles.weatherTableData}>
          <Typography variant="body2">Other data!</Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={Boolean(this.state.anchorEl) ? 'long-menu' : undefined}
            aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem
              key={this.props.row.id}
              onClick={() => {
                this.handleClose()
                this.setState({editModal: true})
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              Edit
            </MenuItem>
            <MenuItem
              key={this.props.row.id}
              onClick={() => {
                this.handleClose()
                this.props
                  .deletePerson(this.props.row.id)
                  .then(r => {
                    store.dispatch(setDashboardData(r))
                  })
                  .finally(() => {
                    this.handleClose()
                  })
              }}
            >
              <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>
          {this.state.editModal && this.renderEditModal()}
        </TableCell>
      </TableRow>
    )
  }
}
