import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'
import {TRegisterPersonParams} from '~/api/generated/types/RegisterPerson'

export default class DeletePerson extends PostApi<{}, TPeopleDataResponse> {
  protected getMockResponse(
    {},
    pathPieces: {personId: string}
  ): TPeopleDataResponse {
    return {
      // TODO: Refactor this to have a common MockPeopleDataResponse and then filter it for the one removed in the pathpieces here
      people: [],
    }
  }

  protected getPath(pathPieces: {personId: string}) {
    return serverEndpoints.weatherDeletePerson(pathPieces)
  }

  protected transformResponse(r: TPeopleDataResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
