import * as React from 'react'
import ProvideFormIncremental from '~/components/ProvideForm/incremental'
import {TrueOrFalse, TTrueOrFalse} from '~/components/ProvideForm/utils'
import {FormToggle} from '~/components/Toggle'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {isDarkMode, unreachableCase} from '~/utils'
import styles from './styles.module.css'

interface IProps {
  darkMode: boolean
  setDarkMode: (value: boolean) => Promise<void>
}

type TFormField = 'darkMode'

type TFormData = Record<TFormField, TTrueOrFalse>

export default class SettingsUI extends React.Component<IProps> {
  render() {
    return (
      <div className={styles.settingsPageWrapper}>
        <DSTextDiv size="h1" className={styles.title}>
          Settings
        </DSTextDiv>
        <ProvideFormIncremental<TFormData>
          onSubmit={(fieldName, value) => {
            switch (fieldName) {
              case 'darkMode':
                return this.props.setDarkMode(
                  TrueOrFalse.toBool(value as TTrueOrFalse)
                )
              default:
                return unreachableCase(fieldName)
            }
          }}
          toFormFields={(generateFormFieldProps, formProps) => {
            return (
              <div className={styles.settingsPageWrapper}>
                <FormToggle<TTrueOrFalse, typeof TrueOrFalse>
                  formFieldProps={generateFormFieldProps('darkMode', [], {
                    initialValue: isDarkMode.toString(),
                  })}
                  booleanString={TrueOrFalse}
                  toggleStyle="checkbox"
                  blurOnChange
                >
                  {input => (
                    <span className={styles.betaFeatureInputAndLabel}>
                      {input}
                      <DSText size="body-15">Dark Mode</DSText>
                    </span>
                  )}
                </FormToggle>
              </div>
            )
          }}
        ></ProvideFormIncremental>
      </div>
    )
  }
}
