import classnames from 'classnames'
import React from 'react'
import ReactModal from 'react-modal'
import Card from '~/design-system/Card'
import styles from './styles.module.css'

type TPositionY = 'center' | 'top96'

export interface IModalBaseProps extends React.PropsWithChildren {
  dontCloseOnEscape?: boolean
  onRequestClose: () => void
  containerClass?: string
  /** A subtle design change is that if a form is >= 300px tall, it has slightly higher bottom padding.
   * Set this to true if the total height of your modal (including padding) is >= 300px
   * (Just click on the card in Sketch and look at its height value.)
   */
  over300PxTall: boolean
  // ReactModal `shouldCloseOnOverlayClick` defaults to true
  dontCloseOnOverlayClick?: boolean
  /* Removes the purple background to the blurry overlay behind the modal, and changes the shadow to fit. */
  noBackgroundColor?: boolean
  cardDivider?: boolean
  positionY?: TPositionY

  /**
   * Inevitably, the reusable paddings here, controlled via `over300PxTall` have not aged well.
   * You can turn it off here and still get the tracking / close behavior from DSModal for now until we rework this.
   */
  // TODO combine this with over300pxTall I guess? gross
  noPadding?: boolean
}

export default class DSModal extends React.Component<IModalBaseProps> {
  render() {
    const {
      positionY = 'center', // default centered
    } = this.props

    const onRequestClose = () => {
      this.props.onRequestClose()
    }
    return (
      <ReactModal
        isOpen
        shouldCloseOnOverlayClick={!this.props.dontCloseOnOverlayClick}
        className={classnames(
          styles.modal,
          positionY !== 'center' && styles[positionY]
        )}
        overlayClassName={classnames(
          styles.overlay,
          !this.props.noBackgroundColor && styles.backgroundColor
        )}
        bodyOpenClassName={styles.body}
        onRequestClose={onRequestClose}
        shouldCloseOnEsc={!this.props.dontCloseOnEscape}
      >
        <Card
          className={classnames(
            styles.card,
            !this.props.noPadding &&
              (this.props.over300PxTall ? styles.tallCard : styles.defaultPadding),
            this.props.containerClass
          )}
          transparentBackground={this.props.cardDivider}
          noHover
        >
          {this.props.cardDivider ? (
            <>
              <div className={styles.cardDividerOffWhite} />
              <div className={styles.cardDividerWhite} />
            </>
          ) : (
            <div className={styles.cardBackground} />
          )}
          {this.props.children}
        </Card>
      </ReactModal>
    )
  }
}
