import classnames from 'classnames'
import React from 'react'
import HoverTrackingDiv, {
  IHoverTrackingDivProps,
} from '~/components/UtilityDivs/HoverTrackingDiv'
import styles from './styles.module.css'

export type TCardChildrenFunction = (props: {
  hovered: boolean
}) => React.ReactNode[] | React.ReactNode

type TBorderRadius = '8' | '12'

export interface ICardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<IHoverTrackingDivProps, 'hoveredClassName' | 'divRef'> {
  borderRadius?: TBorderRadius
  children?: React.ReactNode[] | React.ReactNode | TCardChildrenFunction
  noHover?: boolean
  transparentBackground?: boolean
  focusable?: boolean
}

class CardInternal extends React.PureComponent<ICardProps> {
  render() {
    const {
      noHover,
      hoveredClassName,
      transparentBackground,
      borderRadius = '12',
      ...divProps
    } = this.props

    return (
      <HoverTrackingDiv
        {...divProps}
        className={classnames(
          'hoverable',
          styles[`borderRadius${borderRadius}`],
          !transparentBackground && styles.whiteBackground,
          this.props.className
        )}
        hoveredClassName={hoveredClassName}
      >
        {hovered =>
          typeof this.props.children === 'function'
            ? (this.props.children as TCardChildrenFunction)({hovered})
            : this.props.children
        }
      </HoverTrackingDiv>
    )
  }
}

const Card = React.forwardRef<HTMLDivElement, ICardProps>((props, ref) => (
  <CardInternal divRef={ref} {...props} />
))
export default Card
