import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TNewUserParams} from '~/api/generated/types/AdminNewUser'

export default class AdminNewUser extends PostApi<TNewUserParams, {}, {}, {}> {
  protected getMockResponse(params: TNewUserParams): {} {
    return {}
  }

  protected getPath(pathPieces: {}) {
    return serverEndpoints.adminAdminNewUser()
  }

  protected transformResponse(r: {}) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
