import React from 'react'
import {createContext, useContext, useMemo} from 'react'
import {useSelector} from 'react-redux'

const AuthContext = createContext()

export const AuthProvider = ({children, userData}) => {
  const user = useSelector(state => {
    return state.userState.user
  })

  const value = useMemo(
    () => ({
      user,
    }),
    [user]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
