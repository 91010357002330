import * as React from 'react'
import DSLink from '~/design-system/Link'
import User from '~/models/app/User'
import {DSTextDiv} from '~/design-system/DSText'
import styles from './styles.module.css'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'
import WeatherTable from './WeatherTable'
import {TRegisterPersonParams} from '~/api/generated/types/RegisterPerson'

interface IProps {
  registerNewPerson: (registerPersonParams: TRegisterPersonParams) => Promise<any>
  deletePerson: (personId: string) => Promise<TPeopleDataResponse>
  editPersonDetails: (
    registerPersonParams: TRegisterPersonParams,
    personId: string
  ) => Promise<TPeopleDataResponse>
  user: User
}

interface IState {}

export default class WeatherUI extends React.Component<IProps, IState> {
  render() {
    return (
      <div className={styles.weatherWrapper}>
        <DSTextDiv size="h1" className={styles.header}>
          Weather!
        </DSTextDiv>

        <WeatherTable
          registerNewPerson={this.props.registerNewPerson}
          deletePerson={this.props.deletePerson}
          editPersonDetails={this.props.editPersonDetails}
        />

        <DSTextDiv size="tiny" className={styles.inspiration}>
          Weather! makes it easy to stay connected with the people who matter most.
          Get updates on what's happening in their world so you can be there for
          them.
        </DSTextDiv>
      </div>
    )
  }
}
