import classnames from 'classnames'
import * as React from 'react'
import {Component} from 'react'
import styles from './styles.module.css'

interface IProps {
  className?: string
  darkBackground?: boolean
}

export default class LoadingSpinner extends Component<IProps> {
  render() {
    return (
      <div
        className={classnames(
          styles['loader'],
          styles['default-style'],
          this.props.darkBackground && styles['dark-bg-style'],
          this.props.className
        )}
      />
    )
  }
}
