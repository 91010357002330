import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  defer,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import AdminIndexPage from '~/pages/Admin/Index'
import AdminNewUserPage from '~/pages/Admin/NewUser'
import SignInPage from '~/pages/Auth/SignIn'
import SignUpPage from '~/pages/Auth/SignUp'
import SignOutPage from '~/pages/Auth/SignOut'
import DashboardPage from '~/pages/Dashboard'
import PlantsPage from '~/pages/Plants'
import WeatherPage from '~/pages/Weather'
import HomePage from '~/pages/Home'
import SettingsPage from '~/pages/Settings'
import {staticURLs} from '~/routing/sitemap'
import FinancePage from '~/pages/Finance'
import {AuthLayout} from '~/components/Routing/AuthLayout'
import {HomeLayout} from '~/components/Routing/HomeLayout'
import {ProtectedLayout} from '~/components/Routing/ProtectedLayout'
import {AdminLayout} from '~/components/Routing/AdminLayout'
import {updateGlobalDataFromServer} from '~/redux/massUpdate'

const getUserData = () => updateGlobalDataFromServer('pageTransition')

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({userPromise: getUserData()})}
      // TODO: Error at this level might be best as a just a logout/render of SignOutPage?
      errorElement={<div>Jacked!</div>}
    >
      <Route element={<HomeLayout />}>
        <Route path={staticURLs.home} element={<HomePage />} />

        {/* Auth */}
        <Route path={staticURLs.auth.signin} element={<SignInPage />} />
        <Route path={staticURLs.auth.signup} element={<SignUpPage />} />
      </Route>
      <Route path={staticURLs.auth.signout} element={<SignOutPage />} />

      <Route element={<ProtectedLayout />}>
        {/* Common routes */}
        <Route path={staticURLs.settings.index} element={<SettingsPage />} />
        {/* Pages */}
        <Route path={staticURLs.dashboard} element={<DashboardPage />} />

        {/* Plants! */}
        <Route path={staticURLs.plants} element={<PlantsPage />} />

        {/* Weather! */}
        <Route path={staticURLs.weather} element={<WeatherPage />} />

        {/* Finance! */}
        <Route path={staticURLs.finance} element={<FinancePage />} />
      </Route>
      <Route element={<AdminLayout />}>
        {/* Admin */}
        <Route path={staticURLs.admin.index} element={<AdminIndexPage />} />
        <Route path={staticURLs.admin.newUser} element={<AdminNewUserPage />} />
      </Route>

      {/* catch-all route - 404 */}
      {/* TODO: Add MenuAppBar back in here too */}
      <Route
        path="*"
        element={
          <div>
            Not all who wander are lost.... but, uh, you look a little lost. Page Not
            Found.
          </div>
        }
      />
    </Route>
  )
)

const AppRoutes = () => {
  return <RouterProvider router={router} />
}

export default AppRoutes
