import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import SinglePageApplication from '~/pages/SPA'
import * as serviceWorker from '~/serviceWorker'
import store from '~/redux/store'
import {isDarkMode} from '~/utils'
import './global.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// set dark mode on startup
document
  .getElementsByTagName('html')[0]
  .setAttribute('class', isDarkMode ? 'dark' : 'light')

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SinglePageApplication />
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
