import Axios from 'axios'
import * as React from 'react'
import {redirect, useNavigate} from 'react-router'
import {OmitStrict} from 'type-zoo'
import {IApiPathPieces, GetApi, IGetQueryParameters} from '~/api/client'
import APILoggedOutError from '~/api/client/APIError/APILoggedOutError'
import APIError from '~/api/client/APIError/base'
import {IJSONObject} from '~/api/client/types'
import PromisePoller, {IPromisePollerProps} from '~/components/PromisePoller'
import {staticURLs} from '~/routing/sitemap'

interface IProps<
  RequestParamsType extends IGetQueryParameters,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces,
  ResultType = ResponseType
> extends OmitStrict<
    IPromisePollerProps<ResultType, any, APIError>,
    'promiseProps' | 'generatePromise'
  > {
  params: RequestParamsType
  pathPieces: PathPiecesType
  /**
   * Sometimes, you may want to get a new promise result without changing the params/pathPieces.
   * By setting/changing this prop, you can force the manager to re-request the API.
   */
  refreshTrigger?: any
}

/**
 * This class builder shouldn't be used directly.
 * Instead you can use the version of this on any
 * PostApi by using the `PromisePoller` member variable
 * The generic types are just passed through to the PostApi
 */
export const buildGetApiPromisePoller = <
  RequestParamsType extends IGetQueryParameters,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces,
  ResultType = ResponseType
>(
  api: GetApi<RequestParamsType, ResponseType, PathPiecesType, ResultType>
) => {
  return class PostApiPromisePoller extends React.Component<
    IProps<RequestParamsType, ResponseType, PathPiecesType, ResultType>
  > {
    cancelSource = Axios.CancelToken.source()

    componentDidMount() {
      this.cancelSource = Axios.CancelToken.source()
    }

    componentWillUnmount() {
      /* cancel this promise on unmount */
      this.cancelSource.cancel()
    }

    render() {
      const {params, pathPieces, refreshTrigger, ...pullerProps} = this.props
      return (
        <PromisePoller<
          ResultType,
          {
            params: RequestParamsType
            pathPieces: PathPiecesType
            refreshTrigger?: any
          },
          APIError
        >
          {...pullerProps}
          promiseProps={{
            params,
            pathPieces,
            refreshTrigger,
          }}
          generatePromise={promiseProps => {
            const apiCall = api.get(
              promiseProps.params,
              promiseProps.pathPieces,
              this.cancelSource.token
            )

            apiCall.catch((e: APIError) => {
              if (e instanceof APILoggedOutError) {
                // in the case of a logged-out error we should force logout the user
                // otherwise they are going to get weird errors
                console.warn('Session timed out. You must log in again to continue.')
                redirect(staticURLs.auth.signout)
              }
            })

            return apiCall
          }}
        />
      )
    }
  }
}
