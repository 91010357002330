import React from 'react'
import api from '~/api/endpoints'
import AdminNewUserUI from '~/pages/Admin/NewUser/stateless'

export default class NewUserPage extends React.Component<any> {
  render() {
    return (
      <AdminNewUserUI createNewUser={params => api.admin.newUser.post(params, {})} />
    )
  }
}
