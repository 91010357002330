import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'

export type WeatherState = {
  dashboardData: TPeopleDataResponse
}

export type TBetaFeatureSettings = {}

const defaultSettings: WeatherState = {
  dashboardData: {
    people: [],
  },
}

const initialState = ((): WeatherState => {
  return {
    dashboardData: defaultSettings.dashboardData,
  }
})()

const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setDashboardData(
      state: WeatherState,
      action: PayloadAction<TPeopleDataResponse>
    ) {
      const dashboardData = action.payload
      return {...state, dashboardData}
    },
    resetWeather() {
      return initialState
    },
  },
})

const {setDashboardData, resetWeather} = weatherSlice.actions
export {setDashboardData, resetWeather}
export default weatherSlice.reducer
