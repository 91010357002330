import * as React from 'react'
import api from '~/api/endpoints'
import {TSignInResponse} from '~/api/endpoints/auth/SignIn'
import MockConfig from '~/api/mocks/config'
import {massUpdateRedux} from '~/redux/massUpdate'
import {staticURLs} from '~/routing/sitemap'
import {safely} from '~/utils'
import SignInUI from './stateless'
import {redirect} from 'react-router'

interface IProps {}

// app should refresh on login if it was loaded more than 10 minutes ago
const TIME_SINCE_APP_LOAD_TO_REFRESH_MS = 10 * 60 * 1000
const APP_LOADED_AT: Date = new Date()

export default class SignInPage extends React.Component<IProps> {
  loginComplete = (response: TSignInResponse) => {
    MockConfig.loggedOut = false

    massUpdateRedux(response, 'signin')

    console.log('Sign in complete.')

    // TODO: When you click a link and then are asked to sign in, redirect to that link post signin.
    redirect(staticURLs.dashboard)

    // as a temporary solution to users having old frontend code,
    // just refresh on login if it's been a while since the app was reloaded.
    if (Date.now() - APP_LOADED_AT.getTime() > TIME_SINCE_APP_LOAD_TO_REFRESH_MS) {
      console.warn('Reloading the app after sign-in to get the latest version.')
      window.location.reload()
    }
  }

  render() {
    return (
      <SignInUI
        signIn={form => api.auth.signIn.post(form, {})}
        signInSuccess={this.loginComplete}
      />
    )
  }
}
