import * as React from 'react'
import {connect} from 'react-redux'
import SettingsUI from '~/pages/Settings/stateless'
import {ReduxState} from '~/redux/reducers/root'
import {setDarkMode} from '~/redux/reducers/settings'

interface IReduxDispatchProps {
  setDarkMode: (darkMode: boolean) => void
}
interface IReduxProps extends Pick<ReduxState, 'settingsState'> {}
interface IProps extends IReduxProps, IReduxDispatchProps {}

interface IState {}

class SettingsPage extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <SettingsUI
        darkMode={this.props.settingsState.darkMode}
        setDarkMode={(isDarkMode: boolean) => {
          return new Promise(resolve => {
            this.props.setDarkMode(isDarkMode)
            document
              .getElementsByTagName('html')[0]
              .setAttribute('class', isDarkMode ? 'dark' : 'light')
            resolve()
          })
        }}
      />
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  settingsState: state.settingsState,
})
const mapDispatchToProps = {
  setDarkMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
