import * as R from 'ramda'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import store from '~/redux/store'
import {runtimeTypeValidation, TExpectationDescriptor} from '~/utils'
import {
  LocalStorageManager,
  TLocalStorageGetResult,
  TLocalStorageKey,
} from '~/utils/LocalStorageManager'
import {
  TUserNotificationPerSettingOptions,
  TUserNotificationSettings,
} from '~/utils/UserNotifications'

export type SettingsState = {
  notifications: TUserNotificationSettings
  betaFeatures: TBetaFeatureSettings
  darkMode: boolean
}

export type TBetaFeatureSettings = {
}

const deserializeNotifications = (
  stored: unknown
): TLocalStorageGetResult<Partial<TUserNotificationSettings>> => {
  const perSettingRawExpectation: TExpectationDescriptor<TUserNotificationPerSettingOptions> = {
    sound: 'boolean',
    browserNotification: 'boolean',
  }
  const expectations: TExpectationDescriptor<TUserNotificationSettings> = {
    myTurn: [perSettingRawExpectation, 'undefined'],
    someoneElsesTurn: [perSettingRawExpectation, 'undefined'],
    attackFromMe: [perSettingRawExpectation, 'undefined'],
    attackAgainstMe: [perSettingRawExpectation, 'undefined'],
    attackUninvolved: [perSettingRawExpectation, 'undefined'],
    spectatorRequestReceived: [perSettingRawExpectation, 'undefined'],
  }

  if (
    runtimeTypeValidation<Partial<TUserNotificationSettings>>(expectations, stored)
  ) {
    return {result: stored, type: 'success'}
  }

  return R.tap(x => console.log('notification deserialization failed:', x), {
    type: 'failure',
    error: `notifications did not deserialize properly: ${JSON.stringify(stored)}`,
  })
}

const deserializeBetaFeatures = (
  stored: unknown
): TLocalStorageGetResult<Partial<TBetaFeatureSettings>> => {
  const expectations: TExpectationDescriptor<TBetaFeatureSettings> = {
  }

  if (runtimeTypeValidation<Partial<TBetaFeatureSettings>>(expectations, stored)) {
    return {result: stored, type: 'success'}
  }

  return R.tap(
    x => console.log('beta feature settings deserialization failed:', x),
    {
      type: 'failure',
      error: `beta feature settings did not deserialize properly: ${JSON.stringify(
        stored
      )}`,
    }
  )
}

const defaultSettings: SettingsState = {
  notifications: {
    myTurn: {sound: true, browserNotification: true},
    someoneElsesTurn: {sound: true, browserNotification: false},

    attackFromMe: {sound: true, browserNotification: false},
    attackAgainstMe: {sound: true, browserNotification: true},
    attackUninvolved: {sound: true, browserNotification: false},

    spectatorRequestReceived: {sound: true, browserNotification: false},
  },
  betaFeatures: {},
  darkMode: true,
}

const initialState = ((): SettingsState => {
  const notificationsResult = LocalStorageManager.mapSuccess(
    LocalStorageManager.getObject('settings:notifications'),
    deserializeNotifications
  )

  const betaFeaturesResult = LocalStorageManager.mapSuccess(
    LocalStorageManager.getObject('settings:betaFeatures'),
    deserializeBetaFeatures
  )

  return {
    notifications: {
      ...defaultSettings.notifications,
      ...LocalStorageManager.successOr(
        notificationsResult,
        defaultSettings.notifications
      ),
    },
    betaFeatures: {
      ...defaultSettings.betaFeatures,
      ...LocalStorageManager.successOr(
        betaFeaturesResult,
        defaultSettings.betaFeatures
      ),
    },
    darkMode:
      LocalStorageManager.successOr(
        LocalStorageManager.getObject('settings:darkMode'),
        defaultSettings.darkMode
      ),
  }
})()

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setNotifications(
      state: SettingsState,
      action: PayloadAction<TUserNotificationSettings>
    ) {
      const notifications = action.payload
      LocalStorageManager.setObject('settings:notifications', notifications)
      return {...state, notifications}
    },
    setBetaFeatures(
      state: SettingsState,
      action: PayloadAction<TBetaFeatureSettings>
    ) {
      const betaFeatures = action.payload
      LocalStorageManager.setObject('settings:betaFeatures', betaFeatures)
      return {...state, betaFeatures}
    },
    setDarkMode(
      state: SettingsState,
      action: PayloadAction<boolean>
    ) {
      const darkMode = action.payload
      LocalStorageManager.setObject('settings:darkMode', darkMode)
      return {...state, darkMode}
    },
    resetSettings() {
      return initialState
    },
  },
})

const tryJSONParse = (v: any): unknown => {
  try {
    return JSON.parse(v)
  } catch {
    return v
  }
}

window.addEventListener('storage', event => {
  const notificationsKey: TLocalStorageKey = 'settings:notifications'
  const betaFeaturesKey: TLocalStorageKey = 'settings:betaFeatures'

  if (event.key === notificationsKey) {
    LocalStorageManager.whenSuccessful(
      deserializeNotifications(tryJSONParse(event.newValue)),
      notifs =>
        store.dispatch(
          settingsSlice.actions.setNotifications({
            ...defaultSettings.notifications,
            ...notifs,
          })
        )
    )
  } else if (event.key === betaFeaturesKey) {
    LocalStorageManager.whenSuccessful(
      deserializeBetaFeatures(tryJSONParse(event.newValue)),
      notifs =>
        store.dispatch(
          settingsSlice.actions.setBetaFeatures({
            ...defaultSettings.betaFeatures,
            ...notifs,
          })
        )
    )
  }
})

const {
  setNotifications,
  setBetaFeatures,
  setDarkMode,
  resetSettings,
} = settingsSlice.actions
export {setNotifications, setBetaFeatures, setDarkMode, resetSettings}
export default settingsSlice.reducer
