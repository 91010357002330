import * as React from 'react'
import {Navigate, useOutlet} from 'react-router-dom'
import {useAuth} from '~/hooks/useAuth'

// This layout exist to ensure users are admins before accessing admin, internal-only routes
export const AdminLayout = () => {
  const {user} = useAuth()
  const outlet = useOutlet()

  // TODO: this needs to check the User model admin field instead
  if (!user) {
    return <Navigate to="/" />
  }

  return (
    <div>
      {/* TODO: FEM top bar stuff here? */}
      {outlet}
    </div>
  )
}
