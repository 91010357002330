import * as React from 'react'
import {useState} from 'react'
import {Row, Col, Drawer} from 'antd'
import {withTranslation} from 'react-i18next'
import Container from '../../common/Container'
import {SvgIcon} from '../../common/SvgIcon'
import {Button} from '../../common/Button'
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from './styles'
import {useNavigate} from 'react-router'
import {staticURLs} from '~/routing/sitemap'

const FinanceFEMHeader = ({t}: any) => {
  const [visible, setVisibility] = useState(false)
  const navigate = useNavigate()

  const showDrawer = () => {
    setVisibility(!visible)
  }

  const onClose = () => {
    setVisibility(!visible)
  }

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement
      element.scrollIntoView({
        behavior: 'smooth',
      })
      setVisibility(false)
    }
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo('product')}>
          <Span>{t('Features')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo('pricing')}>
          <Span>{t('Pricing')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{width: '180px'}}
          onClick={() => navigate(staticURLs.auth.signup)}
        >
          <Span>
            <Button>{t('Sign Up')}</Button>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{width: '180px'}}
          onClick={() => navigate(staticURLs.auth.signin)}
        >
          <Span>
            <Button color="#fff">{t('Sign In')}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    )
  }

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="120px" height="100px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{marginBottom: '2.5rem'}}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  )
}

export default withTranslation()(FinanceFEMHeader)
