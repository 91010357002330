import * as React from 'react'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import {styled} from '@mui/material/styles'
import User from '~/models/app/User'
import styles from './styles.module.css'

import {
  TCreateMappingParams,
  TListMappingsResponse,
} from '~/api/generated/types/CreateMapping'
import {TMappingData, TMappingResponse} from '~/api/generated/types/common'
import {connect} from 'react-redux'
import {ReduxState} from '~/redux/reducers/root'

// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the plugin code
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import {ActualFileObject, FilePondInitialFile} from 'filepond'
import {TConvertParams, TConvertResponse} from '~/api/generated/types/Convert'
import {QuestionTooltip} from '~/components/Tooltip'
import store from '~/redux/store'
import {setDashboardData} from '~/redux/reducers/finance'

// Register the plugin
registerPlugin(FilePondPluginFileEncode)

interface IProps {
  createMapping: (
    createMappingParams: TCreateMappingParams
  ) => Promise<TListMappingsResponse>
  convertFile: (convertParams: TConvertParams) => Promise<TConvertResponse>
  user: User
  mappings: TListMappingsResponse
}

interface IState {
  selectedMapping?: string
  addMappingOpen: boolean
  mappingName?: string
  mappingColumns: TMappingData[]
  files:
    | (string | FilePondInitialFile | Blob | ActualFileObject | File)[]
    | undefined
  fileData: string | undefined
  convertedData: string | undefined
  convertedFileName: string | undefined
}

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

class FinanceUI extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    // this.pondref = React.createRef();
  }
  state = {
    selectedMapping: undefined,
    addMappingOpen: false,
    mappingName: undefined,
    mappingColumns: [],
    files: [],
    fileData: undefined,
    convertedData: undefined,
    convertedFileName: undefined,
  }

  handleInit() {
    console.log('FilePond instance has initialised')
  }

  handleClickOpen = () => {
    this.setState({addMappingOpen: true})
  }

  handleClose = () => {
    this.setState({addMappingOpen: false})
  }

  handleSubmit = () => {
    console.log('submitting')
    const dataToSubmit: TCreateMappingParams = {
      createMappingName: this.state.mappingName!,
      createMappingFields: this.state.mappingColumns,
    }
    console.log(dataToSubmit)
    this.props
      .createMapping(dataToSubmit)
      .then(response => {
        console.log(response)
        store.dispatch(setDashboardData(response))
      })
      .finally(() => {
        this.setState({addMappingOpen: false})
      })
  }

  handleUpload = () => {
    console.log('uploading')
    this.props
      .convertFile({
        fileBs: this.state.fileData!,
        fileName: this.state.files[0].name,
        mappingId: this.state.selectedMapping!,
      })
      .then(response => {
        console.log(response)
        this.setState({
          files: [],
          convertedData: response.convertedFileBs,
          convertedFileName:
            this.state.files[0].name.split('.')[0] + '_converted.csv',
        })
      })
      .finally(() => {
        // this.setState({addMappingOpen: false})
        console.log('finally')
      })
  }

  downloadLink = (convertedData: string, convertedFileName: string) => {
    return (
      <a
        href={'data:application/octet-stream;base64,' + convertedData}
        download={convertedFileName}
      >
        Download converted file
      </a>
    )
  }

  currentMappingName = (mappingId: string, mappings: TMappingResponse[]) => {
    const matching = mappings.find(mapping => {
      return mapping.mappingId === mappingId
    })
    if (!!matching) {
      return matching.mappingName
    } else {
      return 'unknown'
    }
  }

  isUndefinedOrEmpty = (str: string | undefined) => {
    return str === undefined || str === ''
  }

  renderMappings = () => {
    const {mappings} = this.props
    return mappings.mappingResponse.map(mapping => {
      return (
        <ListItem key={mapping.mappingId}>
          <ListItemButton
            onClick={() => this.setState({selectedMapping: mapping.mappingId})}
            selected={this.state.selectedMapping === mapping.mappingId}
          >
            <Typography variant="body1">{mapping.mappingName}</Typography>
          </ListItemButton>
        </ListItem>
      )
    })
  }

  renderSelectedMappings = () => {
    const {mappings} = this.props
    const selectedMapping = mappings.mappingResponse.find(mapping => {
      return mapping.mappingId === this.state.selectedMapping
    })
    if (selectedMapping) {
      return (
        <>
          {selectedMapping.mappingFields.map(mapData => {
            return (
              <ListItem
                key={mapData.mappingFieldInitialColumn}
                alignItems="center"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="body1">
                  {mapData.mappingFieldInitialColumn}
                </Typography>
                <Typography variant="body1">{'➡'}</Typography>
                <Typography variant="body1">
                  {mapData.mappingFieldFinalColumn}
                </Typography>
              </ListItem>
            )
          })}
        </>
      )
    } else {
      return <Typography variant="body2">Select a mapping</Typography>
    }
  }

  render() {
    return (
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {/* Three grid items that each contain a Card */}
        <Grid xs={2}>
          <Card>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4">Extract</Typography>
                <QuestionTooltip text="Define the columns we want to pull from your existing CSV and how to transform that data." />
              </div>

              <List sx={{width: '100%', maxWidth: 360}}>
                {this.renderMappings()}
              </List>
              <div>
                <Button variant="outlined" onClick={this.handleClickOpen}>
                  Add New Mapping
                </Button>
                <Dialog open={this.state.addMappingOpen} onClose={this.handleClose}>
                  <DialogTitle>Add A Mapping</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Create a new mapping between columns in your files.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Name this mapping"
                      type="text"
                      fullWidth
                      variant="standard"
                      defaultValue={this.state.mappingName}
                      onChange={e => {
                        this.setState({mappingName: e.target.value})
                      }}
                      error={this.isUndefinedOrEmpty(this.state.mappingName)}
                    />
                    <div>
                      {/* Subfields for mapping columns */}
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                        }}
                      >
                        {this.state.mappingColumns.map((colTup, idx) => (
                          <ListItem
                            sx={{
                              '& .MuiTextField-root': {m: 1},
                            }}
                            key={idx}
                          >
                            <TextField
                              autoFocus
                              id="name"
                              label="Initial Column Name"
                              type="text"
                              variant="standard"
                              defaultValue={colTup[0]}
                              onChange={e => {
                                this.setState(prevState => {
                                  const oldMappingColumns = prevState.mappingColumns
                                  const newUpdate = oldMappingColumns[idx]
                                  const newTup = {
                                    ...newUpdate,
                                    mappingFieldInitialColumn: e.target.value,
                                  }
                                  oldMappingColumns[idx] = newTup
                                  return {mappingColumns: oldMappingColumns}
                                })
                              }}
                              error={
                                this.isUndefinedOrEmpty(
                                  this.state.mappingColumns[idx]
                                    .mappingFieldInitialColumn
                                ) ||
                                this.isUndefinedOrEmpty(
                                  this.state.mappingColumns[idx]
                                    .mappingFieldFinalColumn
                                )
                              }
                            />

                            <TextField
                              id="name"
                              label="Final Column Name"
                              type="text"
                              variant="standard"
                              defaultValue={colTup[1]}
                              onChange={e => {
                                this.setState(prevState => {
                                  const oldMappingColumns = prevState.mappingColumns
                                  const newUpdate = oldMappingColumns[idx]
                                  const newTup = {
                                    ...newUpdate,
                                    mappingFieldFinalColumn: e.target.value,
                                  }
                                  oldMappingColumns[idx] = newTup
                                  return {mappingColumns: oldMappingColumns}
                                })
                              }}
                              error={
                                this.isUndefinedOrEmpty(
                                  this.state.mappingColumns[idx]
                                    .mappingFieldInitialColumn
                                ) ||
                                this.isUndefinedOrEmpty(
                                  this.state.mappingColumns[idx]
                                    .mappingFieldFinalColumn
                                )
                              }
                            />
                          </ListItem>
                        ))}
                        <ListItemButton
                          onClick={e =>
                            this.setState(prevState => {
                              return {
                                mappingColumns: prevState.mappingColumns.concat([
                                  {
                                    mappingFieldInitialColumn: '',
                                    mappingFieldFinalColumn: '',
                                  },
                                ]),
                              }
                            })
                          }
                        >
                          Add another column map
                        </ListItemButton>
                      </List>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    {/* TODO: Don't let this submit when there are form errors */}
                    <Button onClick={this.handleSubmit}>Create Mapping</Button>
                    <Button onClick={this.handleClose} color="secondary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={5}>
          <Card>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4">Transform</Typography>
                <QuestionTooltip text="This is the transformation we'll apply when you upload a CSV in the next step." />
              </div>
              <List sx={{width: '100%', maxWidth: 360}}>
                {this.renderSelectedMappings()}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={2}>
          <Card>
            <CardContent>
              <Typography variant="h4">Load</Typography>
              {!!this.state.selectedMapping ? (
                <>
                  <Typography variant="body1">Upload a CSV file to apply</Typography>
                  <Typography variant="body2">
                    {this.currentMappingName(
                      this.state.selectedMapping,
                      this.props.mappings.mappingResponse
                    )}
                  </Typography>
                  <FilePond
                    ref={ref => (this.pond = ref)}
                    files={this.state.files}
                    allowMultiple={false}
                    name="files" /* sets the file input name, it's filepond by default */
                    oninit={() => this.handleInit()}
                    allowFileEncode={true}
                    onupdatefiles={fileItems => {
                      // Set currently active file objects to this.state
                      this.setState({
                        files: fileItems.map(fileItem => fileItem.file),
                      })
                    }}
                    onpreparefile={item => {
                      this.setState({fileData: item.getFileEncodeDataURL()})
                    }}
                  />
                  {this.state.files.length > 0 && !!this.state.selectedMapping && (
                    <Button variant="outlined" onClick={this.handleUpload}>
                      Upload
                    </Button>
                  )}
                  {!!this.state.convertedData && !!this.state.convertedFileName && (
                    <Button variant="outlined">
                      {this.downloadLink(
                        this.state.convertedData,
                        this.state.convertedFileName
                      )}
                    </Button>
                  )}
                </>
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  mappings: state.financeState.mappings,
})

export default connect(mapStateToProps)(FinanceUI)
