import * as React from 'react'
import {Link} from 'react-router-dom'
import {Navigate, useOutlet} from 'react-router-dom'
import {useAuth} from '~/hooks/useAuth'
import {staticURLs} from '~/routing/sitemap'
import {safelyOr} from '~/utils'
import MenuAppBar from '../MenuAppBar'

// This layout exist to ensure users are logged in before accessing protected routes
export const ProtectedLayout = () => {
  const {user} = useAuth()
  const outlet = useOutlet()

  if (!user) {
    return <Navigate to="/" />
  }

  return (
    <div>
      <div>
        <div>
          {safelyOr(
            user,
            u => (
              <MenuAppBar user={u} />
            ),
            null
          )}
        </div>
      </div>
      {/* <div>{this.props.children}</div>       */}
      {outlet}
    </div>
  )
}
