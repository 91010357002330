// NOTE: this should NOT depend on any pages or components, since this often introduces circular dependencies.
// you can put your method in pages/utils.ts instead.
import {format} from 'date-fns'
import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import * as React from 'react'
import * as M from '~/utils/Map'
import {SetCreationParameter} from '~/utils/Set'
import * as S from '~/utils/Set'
import {TReactSetStateStateParameter} from '~/utils/types'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}

/* Returns a promise that will resolve in the given number of milliseconds */
export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

// TSLint doesn't know about exhaustive switch cases.
// this is a nice helper to use in a `default` case, where
// the compiler can actually warn us if it will ever be hit, so
// we can catch bugs in cases where, i.e., we add a value to an enum but don't
// account for it somewhere
// source: https://github.com/palantir/tslint/issues/696#issuecomment-468640023
// once ESLint implements this and our other rules, we might want to switch to it:
//   https://github.com/typescript-eslint/typescript-eslint/issues/281
export const unreachableCase = (v: never): never => {
  // in dev, where we let the app build with type errors, this is nicer than `return v`
  throw new Error(`Reached an unreachable case: ${v}`)
}

export const isDarkMode: boolean =
  (localStorage.getItem('settings:darkMode') ||
    window.matchMedia('(prefers-color-scheme: dark)').matches.toString()) === 'true'

// we want to safely handle (rather than crash) some unreachable cases, for things like backend-generated
// string unions that may be added to in the future.
export const safeUnreachableCase = <T>(v: never, retVal: T): T => {
  // notifyBugsnag(
  //   {
  //     errorClass: 'bug',
  //     errorMessage: `Reached an unreachable case: ${v}. Perhaps you need to generate types for the frontend?`,
  //   },
  //   {
  //     metaData: {
  //       unreachableCase: v,
  //     },
  //   }
  // )
  console.error(`Reached an unreachable case: ${v}`)
  return retVal
}

const WHITE_SPACES = [
  ' ',
  '\n',
  '\r',
  '\t',
  '\f',
  '\v',
  '\u00A0',
  '\u1680',
  '\u180E',
  '\u2000',
  '\u2001',
  '\u2002',
  '\u2003',
  '\u2004',
  '\u2005',
  '\u2006',
  '\u2007',
  '\u2008',
  '\u2009',
  '\u200A',
  '\u2028',
  '\u2029',
  '\u202F',
  '\u205F',
  '\u3000',
]

export const fancyApostrophe = '’'

export const prettyUSPhoneNumber = (phoneNumber: string): string => {
  const fixed = phoneNumber.replace(/\D/g, '')

  const tenDigitFormat = (pn: string): string =>
    `(${pn.substr(0, 3)}) ${pn.substr(3, 3)}–${pn.substr(6)}`

  if (fixed.length < 10) {
    console.warn("Can't format a short phone number")
    return phoneNumber
  } else if (fixed.length > 11) {
    // international I guess, don't touch it for now?
    console.warn("Can't format a long phone number")
    return phoneNumber
  } else if (fixed.length === 10) {
    return tenDigitFormat(fixed)
  } else {
    // length 11 - if country code is 1, then format, else leave it alone
    if (!fixed.startsWith('1')) {
      return phoneNumber
    }
    return `+1 ${tenDigitFormat(fixed.substr(1))}`
  }
}

// the regex is modified from https://stackoverflow.com/a/6967885/2544629
// changes:
//   - length of part after the country code is checked in another place
// in a match, the whole match will include the + and $1 won't.
/* eslint-disable max-len */
export const PHONE_NUMBER_COUNTRY_CODE_PREFIX_REGEX =
  /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)/

const abbreviationUnits: [string, number][] = [
  ['Quint.', 1_000_000_000_000_000_000],
  ['Quad.', 1_000_000_000_000_000],
  ['T', 1_000_000_000_000],
  ['B', 1_000_000_000],
  ['M', 1_000_000],
  ['K', 1_000],
]

export const dollarsAbbreviator = (
  dollars: number | undefined,
  options: {neverShowCents: boolean; withDollarSign: boolean}
): string => {
  // 0 is false so watch out!
  if (dollars !== 0 && !dollars) {
    return ''
  }
  const absDollars = Math.abs(dollars)

  const abbrUnit: [string, number] | undefined = abbreviationUnits.find(
    ([abbr, amt]) => amt <= absDollars
  )

  if (abbrUnit) {
    return (
      dollarsPrettifier(dollars / abbrUnit[1], options.withDollarSign, 0) +
      abbrUnit[0]
    )
  } else if (absDollars >= 1000) {
    return dollarsPrettifier(dollars, options.withDollarSign, 0)
  } else {
    return dollarsPrettifier(
      dollars,
      options.withDollarSign,
      options.neverShowCents ? 0 : 2
    )
  }
}

export const dollarsToCents = (dollars: number): number => Math.round(dollars * 100)
export const centsToDollars = (cents: number): number => cents / 100

/**
 *  Returns a three-character string representing the fractional part of the given dollar amount
 *  in the format `.DD` where `D` is a number. E.g. `.00`, `.09`, `.80`.
 */
export const fractionalDollarPartPrettifier = (dollars: number): string => {
  const decimalAndCentsString =
    R.last((dollars + '').match(/\.\d{1,2}/g) || ['.0'])! + '0'
  return decimalAndCentsString.slice(0, 3)
}

/** Returns a pretty dollar string from the given cents value. E.g. `1042` -> `$10.42` */
export const centsPrettifier = (
  cents: number | undefined,
  withDollarSign: boolean = true,
  centsDigits: 2 | 0 = 2
): string => {
  return dollarsPrettifier(
    safely(cents, centsToDollars),
    withDollarSign,
    centsDigits
  )
}

/** Returns a pretty dollar string from the given cents value. E.g. `1042.99` -> `$1,042.99` */
export const dollarsPrettifier = (
  dollars: number | undefined,
  withDollarSign: boolean = true,
  centsDigits: 2 | 0 = 2
): string => {
  if (dollars === undefined || isNaN(dollars)) {
    return ''
  }

  if (typeof Intl === 'undefined') {
    // do our best for this very old browser.
    return `${withDollarSign ? '$' : ''}${
      centsDigits === 0 ? Math.trunc(dollars) : dollars
    }`
  }

  const options = withDollarSign
    ? {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: centsDigits,
        maximumFractionDigits: centsDigits,
      }
    : {
        minimumFractionDigits: centsDigits,
        maximumFractionDigits: centsDigits,
      }

  const formatter = new Intl.NumberFormat('en-US', options)
  // use the 'correct' minus sign. it's longer than a hyphen, I swear.
  return formatter.format(dollars).replace('-', '−')
}

/**
 * Formats a date conditionally, based on whether it is further than a year from now or not.
 * @param {Date} date
 * @param {string} olderFormat Format to use on a date further than a year ago/in the future
 * @param {string} recentFormat Format to use on a date less than a year ago/in the future
 * @returns {string} Formatted date string
 */
export const formatDateByAge = (
  date: Date,
  furtherFormat: string = 'MMM yyyy',
  recentFormat: string = 'MMM d'
) => {
  const now = new Date()

  const yearFromNow = new Date(now)
  yearFromNow.setFullYear(now.getFullYear() + 1)

  const yearAgo = new Date(now)
  yearAgo.setFullYear(now.getFullYear() - 1)

  if (date >= yearFromNow || date <= yearAgo) {
    return format(date, furtherFormat)
  } else {
    return format(date, recentFormat)
  }
}

export const initialsFromWords = (count: number, name?: string) => {
  return initialsArrayFromWords(name, count).join('')
}

export const initialsArrayFromWords = (
  name?: string,
  limit: number = 0
): string[] => {
  if (!name || name.trim() === '') {
    return []
  }

  let split = R.split(/\s/, name)
  if (limit > 0) {
    split = R.take(limit, split)
  }
  return R.map((word: string) => word[0], split)
}

/**
 * Remove chars from beginning of string.
 */
export const ltrim = (str: string, chars: string[] = WHITE_SPACES) => {
  let start = 0,
    len = str.length,
    charLen = chars.length,
    found = true,
    i,
    c

  while (found && start < len) {
    found = false
    i = -1
    c = str.charAt(start)

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true
        start++
        break
      }
    }
  }

  return start >= len ? '' : str.substr(start, len)
}

export const isEmpty = (str: string | undefined | null): boolean => {
  return R.isNil(str) || trim(str).length === 0
}

/** Checks if the parameter is null or empty (whitespace-only strings are considered 'empty') */
export const isPresent = (str: string | undefined | null): str is string => {
  return !isEmpty(str)
}

/** Checks if the parameter is not null nor undefined */
export const isNotNil = <T>(v: T | undefined | null): v is NonNullable<T> => {
  return RA.isNotNil(v)
}

/** Checks if the object parameter is null or {}
 * just a types wrapper around R.isEmpty
 */
export const isObjPresent = (obj: object | undefined | null): obj is object => {
  return !!obj && !R.isEmpty(obj)
}

export const undefinedIfEmpty = <T extends string>(
  str: T | undefined | null
): T | undefined => {
  return isEmpty(str) ? undefined : str!
}

/** Returns `defaultValue` if `maybe` is `undefined`. */
export const ifUndefined = <T>(maybe: T | undefined, defaultValue: T): T => {
  return maybe === undefined ? defaultValue : maybe
}

/** Returns `defaultValue` if `maybe` is `null`. */
export const ifNull = <T>(maybe: T | null, defaultValue: T): T => {
  return maybe === null ? defaultValue : maybe
}

/** Calls the given callback iff `maybe` is not undefined/null */
export const safely = <T, U>(
  maybe: T | undefined | null,
  then: (t: NonNullable<T>) => U
): U | undefined => {
  if (maybe !== undefined && maybe !== null) {
    return then(maybe as NonNullable<T>)
  } else {
    return undefined
  }
}

/** Calls the given callback iff `maybe` is not undefined/null, otherwise return `orElse` */
export const safelyOr = <T, U>(
  maybe: T | undefined | null,
  then: (t: T) => U,
  orElse: U
): U => {
  if (maybe !== undefined && maybe !== null) {
    return then(maybe)
  } else {
    return orElse
  }
}

/** Get a Set of the keys of a given Record object */
export const recordKeySet = <K extends string | number | symbol>(
  obj: Record<K, any>
): Set<K> => {
  return M.keySet(M.fromRecord(obj))
}

/** Get an Array of the keys of a given Record object */
export const recordKeys = <K extends string | number | symbol>(
  obj: Record<K, any>
): K[] => {
  return M.keys(M.fromRecord(obj))
}

/** Get an Array of the key/value pairs of a given Record object */
export const recordEntries = <K extends string | number | symbol, V>(
  obj: Record<K, V>
): [K, V][] => {
  return M.entries(M.fromRecord(obj))
}

/**
 * Remove chars from end of string.
 */
export const rtrim = (str: string, chars: string[] = WHITE_SPACES) => {
  let end = str.length - 1,
    charLen = chars.length,
    found = true,
    i,
    c

  while (found && end >= 0) {
    found = false
    i = -1
    c = str.charAt(end)

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true
        end--
        break
      }
    }
  }

  return end >= 0 ? str.substring(0, end + 1) : ''
}

/**
 * Remove white-spaces from beginning and end of string.
 */
export const trim = (str: string, chars: string[] = WHITE_SPACES) =>
  ltrim(rtrim(str, chars), chars)

/**
 * Remove non-word chars.
 */
export const removeNonWord = (str: string) =>
  str.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, '')

export const onlyNumbers = (s: string): string => s.replace(/\D/g, '')
export const onlyNumbersAndDashes = (s: string): string => s.replace(/[^\d\-–]/g, '')

export const removeWhitespace = (s: string) => s.replace(/\s/g, '')
export const removeWhitespaceAndDashes = (s: string): string =>
  s.replace(/[\s\-–]/g, '')

export const removeHyphens = (s: string) => s.replace(/-/g, '')

export const onlyAlphanumerics = (s: string) => s.replace(/[^A-z0-9]/g, '')

// /**
//  * Do a full-text, case-insensitive search for a substring. The query can be a regex if `regexSearch` is true.
//  * @returns the index of the start of the substring, if found, or -1 if not (from JS's `String.search`)
//  */
// export const strSearch = (
//   inString: string,
//   query: string,
//   regexSearch: boolean = false
// ) => {
//   const inStringCanonical = replaceAccents(inString).toLowerCase()
//   const queryStringCanonical = replaceAccents(query).toLowerCase()
//
//   // escape special regex characters
//   const noRegexCharsQuery = new RegExp(escapeStringRegexp(queryStringCanonical), 'i')
//
//   return inStringCanonical.search(
//     regexSearch ? queryStringCanonical : noRegexCharsQuery
//   )
// }

/**
 * Replaces all accented chars with regular ones
 */
export const replaceAccents = (str: string) => {
  // Verifies if the String has accents and replace them
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xDE]/g, 'P')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFE]/g, 'p')
      .replace(/[\xFD\xFF]/g, 'y')
  }

  return str
}

/**
 * Convert to lower case, remove accents, remove non-word chars and
 * replace spaces with the specified delimeter.
 * Does not split camelCase text.
 */
export const slugify = (str: string, delimeter: string = '-') => {
  str = replaceAccents(str)
  str = removeNonWord(str)
  str = trim(str) // Should come after removeNonWord
    .replace(/ +/g, delimeter) // Replace spaces with delimeter
    .toLowerCase()

  return str
}

export const areStrings = R.all((s: any) => typeof s === 'string')

/* returns the lowercased file extension of a filename without the leading '.' */
export const fileExtension = (filename: string): string => {
  const a = filename.split('.')
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return ''
  }
  const x = a.pop()
  if (x) {
    return x.toLowerCase()
  } else {
    return ''
  }
}

// data:text/plain;base64,SGVsbG8sIFdvcmxkIQ%3D%3D -> SGVsbG8sIFdvcmxkIQ%3D%3D
export const base64URLToBase64 = (base64URL: string): string => {
  return base64URL.slice(base64URL.indexOf(',') + 1)
}

export const mapObjectToArray = <V, Result>(
  fn: (key: string, value: V, index: number) => Result,
  obj: {[key: string]: V}
): Result[] => {
  return Object.keys(obj).map((key, index) => {
    return fn(key, obj[key], index)
  })
}

// Derived from https://stackoverflow.com/a/30810322/1176156
// Note: The original answer includes some additional styling protection to keep the textarea from displaying
// We may or may not need that.
export const copyTextToClipboard = (text: string): boolean => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.select()

  let success = false
  try {
    success = document.execCommand('copy')
  } catch (err) {
    console.warn('Unable to copy text to clipboard')
  } finally {
    document.body.removeChild(textArea)
    return success
  }
}

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max))
}

export const removeDiacritics = (s: string): string => {
  if (String.prototype.normalize !== undefined) {
    return s
      .normalize('NFD') // Split and remove diacritics: https://stackoverflow.com/a/37511463/1176156
      .replace(/[\u0300-\u036f]/g, '')
  } else {
    // for IE 11, which doesn't support String.prototype.normalize,
    // use this SO attempt instead: https://stackoverflow.com/a/18391901/2544629
    // (the polyfill for this is over 100kb since it does more stuff, so I opted to use this instead)
    // we may find missing stuff here over time, I think that's acceptable
    /* eslint-disable max-len */
    const defaultDiacriticsRemovalMap = [
      {
        base: 'A',
        letters:
          '\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F',
      },
      {base: 'AA', letters: '\uA732'},
      {base: 'AE', letters: '\u00C6\u01FC\u01E2'},
      {base: 'AO', letters: '\uA734'},
      {base: 'AU', letters: '\uA736'},
      {base: 'AV', letters: '\uA738\uA73A'},
      {base: 'AY', letters: '\uA73C'},
      {base: 'B', letters: '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181'},
      {
        base: 'C',
        letters:
          '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E',
      },
      {
        base: 'D',
        letters:
          '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0',
      },
      {base: 'DZ', letters: '\u01F1\u01C4'},
      {base: 'Dz', letters: '\u01F2\u01C5'},
      {
        base: 'E',
        letters:
          '\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E',
      },
      {base: 'F', letters: '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B'},
      {
        base: 'G',
        letters:
          '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E',
      },
      {
        base: 'H',
        letters:
          '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D',
      },
      {
        base: 'I',
        letters:
          '\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197',
      },
      {base: 'J', letters: '\u004A\u24BF\uFF2A\u0134\u0248'},
      {
        base: 'K',
        letters:
          '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2',
      },
      {
        base: 'L',
        letters:
          '\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780',
      },
      {base: 'LJ', letters: '\u01C7'},
      {base: 'Lj', letters: '\u01C8'},
      {base: 'M', letters: '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C'},
      {
        base: 'N',
        letters:
          '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4',
      },
      {base: 'NJ', letters: '\u01CA'},
      {base: 'Nj', letters: '\u01CB'},
      {
        base: 'O',
        letters:
          '\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C',
      },
      {base: 'OI', letters: '\u01A2'},
      {base: 'OO', letters: '\uA74E'},
      {base: 'OU', letters: '\u0222'},
      {base: 'OE', letters: '\u008C\u0152'},
      {base: 'oe', letters: '\u009C\u0153'},
      {
        base: 'P',
        letters: '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754',
      },
      {base: 'Q', letters: '\u0051\u24C6\uFF31\uA756\uA758\u024A'},
      {
        base: 'R',
        letters:
          '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782',
      },
      {
        base: 'S',
        letters:
          '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784',
      },
      {
        base: 'T',
        letters:
          '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786',
      },
      {base: 'TZ', letters: '\uA728'},
      {
        base: 'U',
        letters:
          '\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244',
      },
      {base: 'V', letters: '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245'},
      {base: 'VY', letters: '\uA760'},
      {
        base: 'W',
        letters: '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72',
      },
      {base: 'X', letters: '\u0058\u24CD\uFF38\u1E8A\u1E8C'},
      {
        base: 'Y',
        letters:
          '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE',
      },
      {
        base: 'Z',
        letters:
          '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762',
      },
      {
        base: 'a',
        letters:
          '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250',
      },
      {base: 'aa', letters: '\uA733'},
      {base: 'ae', letters: '\u00E6\u01FD\u01E3'},
      {base: 'ao', letters: '\uA735'},
      {base: 'au', letters: '\uA737'},
      {base: 'av', letters: '\uA739\uA73B'},
      {base: 'ay', letters: '\uA73D'},
      {base: 'b', letters: '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253'},
      {
        base: 'c',
        letters:
          '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184',
      },
      {
        base: 'd',
        letters:
          '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A',
      },
      {base: 'dz', letters: '\u01F3\u01C6'},
      {
        base: 'e',
        letters:
          '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD',
      },
      {base: 'f', letters: '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C'},
      {
        base: 'g',
        letters:
          '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F',
      },
      {
        base: 'h',
        letters:
          '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265',
      },
      {base: 'hv', letters: '\u0195'},
      {
        base: 'i',
        letters:
          '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131',
      },
      {base: 'j', letters: '\u006A\u24D9\uFF4A\u0135\u01F0\u0249'},
      {
        base: 'k',
        letters:
          '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3',
      },
      {
        base: 'l',
        letters:
          '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747',
      },
      {base: 'lj', letters: '\u01C9'},
      {base: 'm', letters: '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F'},
      {
        base: 'n',
        letters:
          '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5',
      },
      {base: 'nj', letters: '\u01CC'},
      {
        base: 'o',
        letters:
          '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275',
      },
      {base: 'oi', letters: '\u01A3'},
      {base: 'ou', letters: '\u0223'},
      {base: 'oo', letters: '\uA74F'},
      {
        base: 'p',
        letters: '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755',
      },
      {base: 'q', letters: '\u0071\u24E0\uFF51\u024B\uA757\uA759'},
      {
        base: 'r',
        letters:
          '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783',
      },
      {
        base: 's',
        letters:
          '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B',
      },
      {
        base: 't',
        letters:
          '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787',
      },
      {base: 'tz', letters: '\uA729'},
      {
        base: 'u',
        letters:
          '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289',
      },
      {base: 'v', letters: '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C'},
      {base: 'vy', letters: '\uA761'},
      {
        base: 'w',
        letters:
          '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73',
      },
      {base: 'x', letters: '\u0078\u24E7\uFF58\u1E8B\u1E8D'},
      {
        base: 'y',
        letters:
          '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF',
      },
      {
        base: 'z',
        letters:
          '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763',
      },
    ]
    /* eslint-enable max-len */

    const diacriticsMap: any = {}
    for (let i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
      const letters = defaultDiacriticsRemovalMap[i].letters
      for (let j = 0; j < letters.length; j++) {
        diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap[i].base
      }
    }

    return s.replace(/[^\u0000-\u007E]/g, a => {
      return diacriticsMap[a] || a
    })
  }
}

/** Removes all keys whose values are `undefined` from `obj` and returns the result. */
export const purgeUndefined = <T>(obj: T): Partial<T> => {
  return R.pickBy<T, Partial<T>>(v => v !== undefined, obj)
}

/**
 * Remove all falsey values from a list. 0, '', false, undefined, and null will be removed.
 * (RA's compact isn't typed nicely for uniform lists. This wrapper is.)
 */
export const compact = <T>(
  list: Array<T | undefined | null>
): Array<NonNullable<T>> => {
  return RA.compact(list)
}

/**
 * Recursively removes `null`, `undefined`, and empty objects/arrays/strings from an object ({}).
 * @param {T} obj The {} object to compact.
 * @returns {Partial<T extends Object> | undefined} The compacted object, or `undefined` if it
 *          was empty after recursively compacting.
 */
export const deepCompact = <T extends object>(
  obj: T | undefined | null
): Partial<T> | undefined => deepPurgeWhen(obj, v => !isObjPresent(v))

/**
 * Removes values from an object recursively when the given predicate returns true for a value.
 * @param {T} obj The object to purge values from
 * @param {(v: any) => boolean} shouldPurge The predicate. Return true if the value should be removed from the object.
 * @returns {Partial<T extends Object> | undefined} The new, purged object, or `undefined` if it
 *          was empty after recursively purging.
 */
export const deepPurgeWhen = <T extends object>(
  obj: T | undefined | null,
  shouldPurge: (v: any) => boolean
): Partial<T> | undefined => {
  if (R.isNil(obj)) {
    return undefined
  }

  const cleansed = RA.omitBy(
    R.isNil,
    R.mapObjIndexed(value => {
      if (RA.isPlainObject(value)) {
        // recurse
        const subCleansed = deepPurgeWhen(value, shouldPurge)
        return shouldPurge(subCleansed) ? undefined : subCleansed
      } else {
        return shouldPurge(value) ? undefined : value
      }
    }, obj)
  ) as Partial<T>

  return shouldPurge(cleansed) ? undefined : cleansed
}

export const convertNullsToUndefined = <T>(value: T): T => {
  if (typeof value === 'object' && !!value) {
    return R.map<T, T>(convertNullsToUndefined, value)
  } else if (value === null) {
    return undefined as any as T
  } else {
    return value
  }
}

/**
 * Calls `replace` to calculate a new value for every key/value pair in `subject` recursively.
 *
 * Useful for things like rewriting:
 *  - values of the key `partnerBank` from `synapse`-> `test`
 *  - values of any key from `null` -> `undefined`
 * no matter how deep that key/value pair may be in an object/array.
 */
export const deepObjectValueReplacement = <T extends any>(
  subject: T,
  replace: <U>(value: U, key: string | number) => U
): T => {
  const recurse = (obj: any, key?: string | number): any => {
    const result = RA.isPlainObj(obj)
      ? R.mapObjIndexed(recurse, obj)
      : RA.isArray(obj)
      ? obj.map(recurse)
      : obj
    // `key` is undefined for the first/top object passed to `deepObjectValueReplacement`
    return key !== undefined ? replace(result, key) : result
  }
  return recurse(subject) as any
}

/**
 * This checks to see if `checkValue` exists in the inEnum enum
 * unfortunately with string enums you can't do `checkValue in inEnum`
 *  so I made this function
 */
export const isInEnum = <T extends object>(
  checkValue: any,
  inEnum: T
): checkValue is T[keyof T] => {
  return R.contains(checkValue, R.values(inEnum))
}

/**
 * Returns a type-ensured enum value if the supplied value is one of the supplied enum's values.
 * @param checkValue
 * @param {T} inEnum
 * @returns {T[keyof T] | undefined} `checkValue`, if it's in the enum, or `undefined` if not.
 */
export const enumValueIfIsInEnum = <T extends object>(
  checkValue: any,
  inEnum: T
): T[keyof T] | undefined => {
  if (isInEnum(checkValue, inEnum)) {
    return checkValue
  } else {
    return undefined
  }
}

export const getInitials = (original: string): string => {
  const MAX_INITIALS = 2
  original = original.trim()

  if (original.length === 0) {
    return ''
  }

  // A string-starting 'The' shouldn't be used in the abbreviation, if it's not the only word in the string
  const excludeString = 'the '
  if (
    original.toLocaleLowerCase().startsWith(excludeString) &&
    original.length > excludeString.length
  ) {
    original = original.substring(excludeString.length)
  }
  // could someday remove interior short words like (of,a,the,for,and,by) too

  const initials = initialsArrayFromWords(original)
  if (initials.length > MAX_INITIALS) {
    return initials[0]
  } else {
    return initials.join('')
  }
}

export const lowerFirst = (s: string): string => {
  return s.slice(0, 1).toLowerCase() + s.slice(1)
}

export const xor = (a: boolean, b: boolean): boolean => (a ? !b : b)

/**
 * Takes a Record object and switches the keys and values. To account for duplicate values in the original Record,
 *   the values in the new Record will be an array.
 * (Same as R.invert, with better typing for Record objects.)
 */
export const invertRecord = <
  T extends string | number | symbol,
  U extends string | number | symbol
>(
  obj: Record<T, U>
): {[key in U]: T[]} => {
  return R.invert(obj) as {[key in U]: T[]}
}

export const invertRecordFlat = <
  T extends string | number | symbol,
  U extends string | number | symbol
>(
  obj: Record<T, U>
): {[key in U]: T} => {
  return R.invertObj(obj as any) as {[key in U]: T}
}

/** Return a partial copy of the given record only including the specified keys. */
export const filterRecord = <K extends string, V extends any>(
  keys: SetCreationParameter<K>, // keys to keep
  record: Record<K, V>
): Partial<Record<K, V>> => {
  // gave up on the types here, but the test validates the cast
  return R.pick(S.values(S.create(keys)), record) as any
}

/** Return a partial copy of the given record after removing the specified keys. */
export const rejectRecord = <K extends string, V extends any>(
  keys: SetCreationParameter<K>, // keys to remove
  record: Record<K, V>
): Partial<Record<K, V>> => {
  // gave up on the types here, but the test validates the cast
  return R.omit(S.values(S.create(keys)), record) as any
}

/** Change the values at each key of a record with the given function. */
export const mapRecord = <K extends string, V extends any, U extends any>(
  mutate: (v: V, k: K, index: number) => U,
  record: Record<K, V>
): Record<K, U> => {
  const retVal = {} as Record<K, U>
  recordEntries(record).forEach(
    (pair, i) => (retVal[pair[0]] = mutate(pair[1], pair[0], i))
  )
  return retVal
}

export const downloadPDF = (download: string, href: string) => {
  // Note: This is not supported by IE and Opera Mini
  var link = document.createElement('a')
  link.download = download
  link.href = href

  // append / remove required for Firefox: https://stackoverflow.com/a/27116581/2544629
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// I couldn't find a place where TS exported this, but they might.
// export type TTypeOf = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function'
const typeofFunc = (typeofVar: any) => {
  return typeof typeofVar
}
type TTypeOf = ReturnType<typeof typeofFunc>

type TTypeOfOrNull = TTypeOf | 'null'

/** An object that describes the expected shape of a POJO using primitive labels returned by the `typeof` operator.
 * For example:
 *
 * {
 *   foo: 'string',
 *   bar: 'number',
 *   baz: {
 *     buzz: 'boolean',
 *   },
 *   biz: 'function',
 *   burp: ['string', 'undefined'],
 * }
 */
export type TExpectationDescriptor<T> = {
  [k in keyof T]:
    | TTypeOfOrNull
    | TExpectationDescriptor<any>
    | (TExpectationDescriptor<any> | TTypeOfOrNull)[]
}

/**
 * Does some simple `typeof` checks at runtime to verify the given `unknown` object at least has the
 * desired shape at each key of the expectation descriptor `expectations`. Supports multi-level objects
 * and unions of primitive types.
 *
 * Useful for reading third-party inputs into more sane types with some guarantees. See the tests for examples.
 */
export const runtimeTypeValidation = <T>(
  expectations: TExpectationDescriptor<T>,
  validate: unknown // hoping this is a Record<keyof T, any>
): validate is T => {
  if (typeof validate !== 'object' || validate === null) {
    return false
  }

  for (const k of Object.keys(expectations)) {
    // @ts-ignore
    const expectedType: any = expectations[k]
    // @ts-ignore
    const actualValue: any = validate[k]

    if (typeof actualValue === expectedType) {
      continue
    }

    /* eslint-disable no-debugger */
    // debugger

    const expectationArray = RA.ensureArray(expectedType)
    // find at least one met expectation
    if (
      expectationArray.find(exp => {
        if (exp === typeof actualValue) {
          return true
        }
        if (exp === 'null' && actualValue === null) {
          // since typeof null === 'object' -_-
          return true
        }
        if (typeof exp === 'object') {
          // recurse
          return runtimeTypeValidation(
            exp as Record<any, TTypeOf | Record<any, TTypeOf>>,
            actualValue
          )
        }
      })
    ) {
      continue
    }

    return false
  }
  return true
}

/* The identity function. */
export const id = <T>(a: T): T => a

const UUID_REGEX = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/

/** Returns the first UUID found in the given string, or `undefined` if none are found. */
export const extractUUID = (str: string): string | undefined => {
  return new RegExp(UUID_REGEX).exec(str)?.[0] ?? undefined
}

/** Like Ramda's partition, but uses an `is` predicate to guarantee the return type of the first partition. */
export const partitionIs = <U extends T, T>(
  fn: (l: T) => l is U,
  list: readonly T[]
): [U[], T[]] => {
  const us: U[] = []
  const ts: T[] = []
  list.forEach(l => {
    if (fn(l)) {
      us.push(l)
    } else {
      ts.push(l)
    }
  })
  return [us, ts]
}

// Get all the possible combinations (unordered) of the given array's members.
// NB: will include `[]` and the full, given array.
// from: https://stackoverflow.com/a/42531964/2544629
export const combinations = <T>(array: T[]): T[][] => {
  /* eslint-disable no-bitwise */
  return new Array(1 << array.length)
    .fill(undefined)
    .map((_1, i) => array.filter((_2, j) => i & (1 << j)))
  /* eslint-enable no-bitwise */
}

/** Wraps `this.setState` in a promise that is resolved in `setState`'s callback. */
export const setStatePromise = <State, K extends keyof State, P>(
  _this: React.Component<P, State>,
  setState: TReactSetStateStateParameter<State, K, P>
): Promise<void> => {
  return new Promise(resolve => _this.setState(setState, resolve))
}

/* eslint-disable no-redeclare */
/** Returns either `thing` iff `!condition`, or `mutate(thing)` iff `condition`. */
function mutateIf<T, U>(thing: T, condition: true, mutate: (t: T) => U): U
function mutateIf<T, U>(thing: T, condition: false, mutate: (t: T) => U): T
function mutateIf<T, U>(thing: T, condition: boolean, mutate: (t: T) => U): T | U
function mutateIf<T, U>(thing: T, condition: boolean, mutate: (t: T) => U): T | U {
  if (condition) {
    return mutate(thing)
  } else {
    return thing
  }
}
export {mutateIf}
/* eslint-enable no-redeclare */
