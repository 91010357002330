import * as React from 'react'
import api from '~/api/endpoints'
import {setDashboardData} from '~/redux/reducers/finance'
import store from '~/redux/store'
import FinanceUI from './stateless'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import {
  TCreateMappingParams,
  TListMappingsResponse,
} from '~/api/generated/types/CreateMapping'
import {useAuth} from '~/hooks/useAuth'
import {TConvertParams} from '~/api/generated/types/Convert'

interface IProps {}

export default function FinancePage(props: IProps) {
  const receiveMappingData = (mappings: TListMappingsResponse) => {
    store.dispatch(setDashboardData(mappings))
  }
  const {user} = useAuth()
  return (
    <api.finance.listMappings.PromiseManager
      params={{}}
      pathPieces={{organizationId: user.organizationId}}
      onSuccess={receiveMappingData}
      then={() => (
        <ScopedCssBaseline>
          <FinanceUI
            createMapping={(params: TCreateMappingParams) =>
              api.finance.createMapping.post(params, {
                organizationId: user.organizationId,
              })
            }
            user={user}
            convertFile={(params: TConvertParams) =>
              api.finance.convert.post(params, {
                organizationId: user.organizationId,
              })
            }
          />
        </ScopedCssBaseline>
      )}
    />
  )
}
