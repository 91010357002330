import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import {staticURLs} from '~/routing/sitemap'
import {TSignInResponse} from '~/api/endpoints/auth/SignIn'
import {NavLink} from 'react-router-dom'
import {LogoContainer} from '~/components/Footer/styles'
import {SvgIcon} from '~/common/SvgIcon'

interface IProps {
  signIn: (form: FormFields) => Promise<TSignInResponse>
  signInSuccess: (response: TSignInResponse) => void
}

interface IState {}

type FormFields = {
  email: string
  password: string
}

export default function SignInUI(props: IProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const pass = data.get('password')
    if (!!email && !!pass) {
      props
        .signIn({
          email: email.toString(),
          password: pass.toString(),
        })
        .then(r => {
          props.signInSuccess(r)
        })
        .finally(() => {})
    } else {
      console.error('no email or password')
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <NavLink to="/">
          <LogoContainer>
            <SvgIcon
              src="logo.svg"
              aria-label="homepage"
              width="101px"
              height="64px"
            />
          </LogoContainer>
        </NavLink>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              {/* TODO: implement this shizz */}
              {/* <Link href="#" variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
            <Grid item>
              <Link href={staticURLs.auth.signup} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
