import * as React from 'react'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import StarIcon from '@mui/icons-material/StarBorder'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import GlobalStyles from '@mui/material/GlobalStyles'
import Container from '@mui/material/Container'
import {staticURLs} from '~/routing/sitemap'
import {useNavigate} from 'react-router'

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: ['1 free transformation per month'],
    buttonText: 'Get started for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '50',
    description: ['Unlimited usage', 'Feature Request Priority'],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: 'Coming Soon',
    description: [
      'Bring in your entire finance team',
      'Share template definitions and workflows',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
]

export default function Pricing(props: {id: string}) {
  const navigate = useNavigate()

  return (
    <div id={props.id}>
      {/* <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} /> */}
      {/* <CssBaseline /> */}

      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{pt: 8, pb: 6}}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          One, monthly price. Hours shaved off your work.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{align: 'center'}}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: theme =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <>
                      {tier.price === 'Coming Soon' ? (
                        <Typography component="h2" variant="h4" color="text.primary">
                          {tier.price}
                        </Typography>
                      ) : (
                        <div>
                          <Typography
                            component="h2"
                            variant="h3"
                            color="text.primary"
                            sx={{textDecoration: 'line-through'}}
                          >
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            /mo
                          </Typography>
                        </div>
                      )}
                    </>
                  </Box>
                  <Box>
                    <Typography variant="h6" color="text.secondary">
                      Free during Open Beta
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map(line => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                {/*                 <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid
          sx={{
            justifyContent: 'center',
            marginTop: '16px',
          }}
          container
        >
          <Button
            variant="contained"
            onClick={() => navigate(staticURLs.auth.signup)}
          >
            Get started for free
          </Button>
        </Grid>
      </Container>
    </div>
  )
}
