import classnames from 'classnames'
import React from 'react'
import {OmitStrict} from 'type-zoo'
import {IFormFieldProps} from '~/components/ProvideForm/types'
import {BooleanString} from '~/components/ProvideForm/utils'
import {IToggleGroupRenderProps} from '~/components/ToggleGroup'

import styles from './styles.module.css'

export type TToggleStyle =
  | 'switch' // iOS-style sliding switch
  | 'checkbox' // classic rounded-square checkbox
  | 'radio' // classic circular radio button
  | 'round-check' // circular radio-button look but selected state is a round checkmark instead of a dot
  | 'check-when-selected' // checkmark appears only when selected, otherwise only the label is visible
  | 'none'

type TChildrenFunc = (input: JSX.Element) => JSX.Element

export interface IToggleProps
  extends OmitStrict<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (checked: boolean, event: React.ChangeEvent) => void
  toggleStyle: TToggleStyle
  vertical?: boolean
  wrapperClassName?: string
  inputRef?: React.Ref<HTMLInputElement>

  // if you are toggling on the onClick on, say, a wrapping div around the toggle, use this to stop a click on the
  // <label> from calling the parent's onClick (a duplicate event handler may cancel out the user's toggle)
  // NB: clicks on the actual <input> won't call a parent's onClick handler, but clicks on a <label> will.
  stopPropagationFromLabelClicks?: boolean

  // some toggle designs are a different color when hovered
  hovered?: boolean

  // we had this default behavior for all radio buttons before,
  // I don't want it everywhere anymore though so now it's a prop for any toggleStyle
  ignoreUncheckEvents?: boolean

  children?: TChildrenFunc
}

class ToggleInternal extends React.Component<IToggleProps> {
  render() {
    const {
      onChange,
      toggleStyle,
      vertical,
      wrapperClassName,
      className,
      inputRef,
      stopPropagationFromLabelClicks,
      hovered,
      ignoreUncheckEvents,
      children,
      ...inputProps
    } = this.props

    const input = (
      <input
        {...inputProps}
        ref={inputRef}
        onChange={e => {
          if (ignoreUncheckEvents && !e.currentTarget.checked) {
            return
          }
          onChange?.(e.currentTarget.checked, e)
        }}
        className={classnames(
          styles['input'],
          styles[toggleStyle],
          hovered && styles['hovered'],
          className
        )}
        type="checkbox"
        onMouseDown={e => e.preventDefault()}
      />
    )

    return (
      <label
        className={classnames({
          [styles['wrapper']]: true,
          [styles['vertical']]: vertical,
          [wrapperClassName || '']: true,
        })}
        onClick={e => stopPropagationFromLabelClicks && e.stopPropagation()}
      >
        {children ? children(input) : input}
      </label>
    )
  }
}

const Toggle = React.forwardRef(
  (props: IToggleProps, ref: React.Ref<HTMLInputElement>) => (
    <ToggleInternal {...props} inputRef={ref} />
  )
)
export default Toggle

export interface IToggleForGroupProps<EnumType extends string>
  extends OmitStrict<IToggleProps, 'checked' | 'onChange'> {
  toggleValue: EnumType
  groupProps: IToggleGroupRenderProps<EnumType>
}

// probably can't use this helper wrapper if you need to forward a ref to the toggle
// because I can't figure out how to keep the generic types in the props working when you use `React.forwardRef`
// see https://github.com/microsoft/TypeScript/issues/30134#issuecomment-580495194 maybe?
export function ToggleForGroup<EnumType extends string>({
  toggleValue,
  groupProps,
  ...toggleProps
}: IToggleForGroupProps<EnumType>) {
  return (
    <ToggleInternal
      {...toggleProps}
      checked={groupProps.isSelected(toggleValue)}
      onChange={() => {
        groupProps.makeSelection(toggleValue)
      }}
    />
  )
}

export interface IFormToggleProps<
  EnumType extends string,
  BS extends BooleanString<EnumType>
> extends OmitStrict<IToggleProps, 'checked'> {
  formFieldProps: IFormFieldProps
  booleanString: BS
  blurOnChange?: boolean
}

// probably can't use this helper wrapper if you need to forward a ref to the toggle
// because I can't figure out how to keep the generic types in the props working when you use `React.forwardRef`
// see https://github.com/microsoft/TypeScript/issues/30134#issuecomment-580495194 maybe?
export function FormToggle<
  EnumType extends string,
  BS extends BooleanString<EnumType>
>({
  formFieldProps,
  booleanString,
  blurOnChange,
  ...toggleProps
}: IFormToggleProps<EnumType, BS>) {
  return (
    <ToggleInternal
      {...toggleProps}
      checked={booleanString.isChecked(formFieldProps.value as EnumType)}
      onChange={(isChecked, event) => {
        event.persist()
        formFieldProps.onChange(booleanString.toString(isChecked), () => {
          blurOnChange && formFieldProps.onBlur()
          toggleProps.onChange?.(isChecked, event)
        })
      }}
    />
  )
}
