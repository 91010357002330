import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TListMappingsResponse} from '~/api/generated/types/CreateMapping'

export type FinanceState = {
  mappings: TListMappingsResponse
}

export type TBetaFeatureSettings = {}

const defaultSettings: FinanceState = {
  mappings: {
    mappingResponse: [],
  },
}

const initialState = ((): FinanceState => {
  return {
    mappings: defaultSettings.mappings,
  }
})()

const financeSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    setDashboardData(
      state: FinanceState,
      action: PayloadAction<TListMappingsResponse>
    ) {
      const mappings = action.payload
      return {...state, mappings}
    },
    resetFinance() {
      return initialState
    },
  },
})

const {setDashboardData, resetFinance} = financeSlice.actions
export {setDashboardData, resetFinance}
export default financeSlice.reducer
