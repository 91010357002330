import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TConvertParams, TConvertResponse} from '~/api/generated/types/Convert'

export default class Convert extends PostApi<TConvertParams, TConvertResponse> {
  protected getMockResponse(params: TConvertParams): TConvertResponse {
    return {
      convertedFileBs:
        'RmluYWwgY29sdW1uLEZpbmFsIGNvbHVtbiAyCmRhdGFwb2ludDEsIGRhdGFwb2ludDIKZGF0YXBvaW50MywgZGF0YXBvaW50NAo=',
    }
  }

  protected getPath(pathPieces: {organizationId: string}) {
    return serverEndpoints.financeConvert(pathPieces)
  }

  protected transformResponse(r: TConvertResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
