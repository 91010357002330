// Throws an error if the specified environment variable is not defined
const environmentVariable = (name: string): string => {
  const value = process.env[name]
  if (!value) {
    if (name === 'REACT_APP_BACKEND_SOURCE') {
      // default environment to mock if none is set
      const defaultBackendSource: TBackendSource = 'mock'
      return defaultBackendSource
    }
    if (name === 'REACT_APP_NGROK_SUBDOMAIN') {
      // optional. ignore
      return 'unset'
    }
    throw new Error(`Expected environment variable ${name}`)
  }
  return value
}

export type TBackendSource =
  | 'mock'
  | 'localhost'
  | 'localhost_named'
  | 'ngrok'
  | 'ngroks' // https version
  // | 'staging'
  | 'production'

export type TDomainName = 'basis.co'
export type TAppName = 'Basis' | 'LedgerETL'

interface IClientEnvironmentConfig {
  backendSource: TBackendSource
  domainName: TDomainName
  appName: TAppName
  // gitSha: string

  // bugsnag: {
  //   apiKey: string
  //   releaseStage: string
  // }
  ngrokSubdomain: string
  nodeEnv: string
}

export const nodeEnv = environmentVariable('NODE_ENV')

// NB: this is also seemingly true in CircleCI during PR build tests
export const inTesting: boolean = nodeEnv === 'test'

/**
 * IMPORTANT: Never put a sensitive environment variable in this configuration. Doing
 * so would expose the value in our public javascript!
 */
const environment: IClientEnvironmentConfig = inTesting
  ? {
      // we always want tests to hit the mocks.
      backendSource: 'mock',
      domainName: 'basis.co',
      appName: 'Basis',
      // gitSha: '1234abcdef',
      // bugsnag: {
      //   apiKey: '',
      //   releaseStage: '',
      // },
      ngrokSubdomain: 'abcdefg',
      nodeEnv,
    }
  : {
      backendSource: environmentVariable(
        'REACT_APP_BACKEND_SOURCE'
      ) as TBackendSource,
      domainName: environmentVariable('REACT_APP_DOMAIN_NAME') as TDomainName,
      appName: environmentVariable('REACT_APP_NAME') as TAppName,
      // gitSha: environmentVariable('GIT_SHA'),

      // bugsnag: {
      //   apiKey: environmentVariable('REACT_APP_BUGSNAG_API_KEY'),
      //   releaseStage: environmentVariable('REACT_APP_BUGSNAG_RELEASE_STAGE'),
      // },
      ngrokSubdomain: environmentVariable('REACT_APP_NGROK_SUBDOMAIN'),
      nodeEnv,
    }

export default environment
