import classnames from 'classnames'
import * as React from 'react'
import {Link as RRDLink, LinkProps} from 'react-router-dom'
import {OmitStrict} from 'type-zoo'
import DSText, {
  DSTextDiv,
  IDSTextDivProps,
  IDSTextProps,
} from '~/design-system/DSText'
import styles from './styles.module.css'

// `true` and `'always'` are functionally the same, as are `false` and `'never'`
type TLinkUnderline = 'never' | 'always' | 'onHover' | boolean

interface IDSLinkBaseProps {
  newTab?: boolean
  underline: TLinkUnderline
  wrapperClassName?: string
  div?: boolean // use a div instead of a span as the wrapper
}

export interface IDSLinkProps
  extends IDSLinkBaseProps,
    OmitStrict<LinkProps, 'color'> {}

/** Wraps `Link` from react-router-dom so we can style it
 * For now assumes that this Link is in DSText already and does not apply any style/size
 * only color.
 *
 * NB: wrapped in a `<span>` (or a `<div>`) so that the `color: inherit` trick (see styles.css) works properly
 */
const DSLink = ({
  newTab,
  className,
  wrapperClassName,
  target,
  underline,
  div,
  ...linkProps
}: IDSLinkProps) => {
  return React.createElement(div ? 'div' : 'span', {
    className: classnames(wrapperClassName),
    children: (
      <RRDLink
        {...linkProps}
        target={newTab ? '_blank' : target}
        className={classnames([
          'hoverable',
          styles['link'],
          styles[`underline-${underline}`],
          className,
        ])}
      >
        {linkProps.children}
      </RRDLink>
    ),
  })
}
export default DSLink

export interface IExternalLinkProps
  extends IDSLinkBaseProps,
    OmitStrict<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'color' | 'onClick'> {}

/** Wraps `<a>` so we can style it
 * For now assumes that this `<a>` is in DSText already and does not apply any style/size
 * only color.
 *
 * NB: wrapped in a `<span>` (or a `<div>`) so that the `color: inherit` trick (see styles.css) works properly
 */
export const DSExternalLink = ({
  newTab,
  className,
  wrapperClassName,
  underline,
  target,
  div,
  ...anchorProps
}: IExternalLinkProps) =>
  React.createElement(div ? 'div' : 'span', {
    className: classnames(wrapperClassName),
    children: (
      <a
        {...anchorProps}
        target={newTab ? '_blank' : target}
        className={classnames([
          styles['link'],
          styles[`underline-${underline}`],
          className,
        ])}
      >
        {anchorProps.children}
      </a>
    ),
  })

interface ILinkTextDivProps
  extends OmitStrict<IDSLinkBaseProps, 'newTab'>,
    OmitStrict<IDSTextDivProps, 'underline' | 'onClick'>,
    Required<Pick<IDSTextDivProps, 'onClick'>> {}

/** Wraps `<DSTextDiv>` so we can style it like a link */
export const DSLinkTextDiv = ({
  className,
  underline,
  ...dsTextProps
}: ILinkTextDivProps) => (
  <DSTextDiv
    {...dsTextProps}
    className={classnames([
      className,
      styles['link'],
      styles[`underline-${underline}`],
    ])}
  >
    {dsTextProps.children}
  </DSTextDiv>
)

interface ILinkTextProps
  extends OmitStrict<IDSLinkBaseProps, 'newTab'>,
    OmitStrict<IDSTextProps, 'underline' | 'onClick'>,
    Required<Pick<IDSTextProps, 'onClick'>> {}

/** Wraps `<DSText>` so we can style it like a link */
export const DSLinkText = ({
  className,
  underline,
  ...dsTextProps
}: ILinkTextProps) => (
  <DSText
    {...dsTextProps}
    className={classnames([
      className,
      styles['link'],
      styles[`underline-${underline}`],
    ])}
  >
    {dsTextProps.children}
  </DSText>
)
