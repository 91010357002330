import * as React from 'react'
import {lazy} from 'react'
import IntroContent from '~/content/IntroContent.json'
import MiddleBlockContent from '~/content/MiddleBlockContent.json'
import AboutContent from '~/content/AboutContent.json'
import ProductContent from '~/content/ProductContent.json'
import ContactContent from '~/content/ContactContent.json'
import Footer from '~/components/Footer'
import FinanceFEMHeader from '~/components/Header'
import {I18nextProvider} from 'react-i18next'
import i18n from '~/translation'
import {Styles} from '~/styles/styles'
import Pricing from '~/components/Pricing'
import {staticURLs} from '~/routing/sitemap'
import {Button} from '../../../common/Button'
import {useNavigate} from 'react-router'
import {MiddleBlockSection} from '~/components/MiddleBlock/styles'

const Contact = lazy(() => import('~/components/ContactForm'))
const MiddleBlock = lazy(() => import('~/components/MiddleBlock'))
const Container = lazy(() => import('~/common/Container'))
const ScrollToTop = lazy(() => import('~/common/ScrollToTop'))
const ContentBlock = lazy(() => import('~/components/ContentBlock'))

const LedgerETLFEM = () => {
  const navigate = useNavigate()
  return (
    <I18nextProvider i18n={i18n}>
      <Styles />
      <FinanceFEMHeader />
      <Container>
        <ScrollToTop />
        <ContentBlock
          type="right"
          title={IntroContent.title}
          content={IntroContent.text}
          button={IntroContent.button}
          icon="developer.svg"
          id="intro"
        />
        {/* <MiddleBlock
          title={MiddleBlockContent.title}
          content={MiddleBlockContent.text}
          button={MiddleBlockContent.button}
        /> */}
        <ContentBlock
          type="left"
          title={AboutContent.title}
          content={AboutContent.text}
          section={AboutContent.section}
          icon="graphs.svg"
          id="about"
        />
        <Pricing id="pricing" />
        <ContentBlock
          type="left"
          title={ProductContent.title}
          content={ProductContent.text}
          icon="waving.svg"
          id="product"
        />
        {/* <Contact
          title={ContactContent.title}
          content={ContactContent.text}
          id="contact"
        /> */}

        <MiddleBlockSection>
          <Button
            key="final-cta"
            fixedWidth={true}
            onClick={() => navigate(staticURLs.auth.signup)}
          >
            Get Started Today
          </Button>
        </MiddleBlockSection>
      </Container>
      <Footer />
    </I18nextProvider>
  )
}

export default LedgerETLFEM
