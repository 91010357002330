// suggestion: use M as an import, i.e.:
// import * as M from '~/utils/Map'

import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import {TTrueOrFalse} from '~/components/ProvideForm/utils'

/**
 * Create a Map from a JS object. Has an undefined check that fromRecord doesn't to support objects w/optional keys.
 * Any keys whose values are undefined will be omitted from the returned Map.
 */
export const fromObj = <K extends string | number | symbol, V>(
  obj: Record<K, V> | {[P in K]?: V}
): Map<K, NonNullable<V>> => {
  const _entries = new Array<[K, NonNullable<V>]>()
  R.forEachObjIndexed(
    (v, k) => v !== undefined && _entries.push([k, v as any as NonNullable<V>]),
    obj
  )
  return new Map(_entries)
}

/** Create a Map from a JS object */
export const fromRecord = <K extends string | number | symbol, V>(
  obj: Record<K, V>
): Map<K, V> => {
  const _entries = new Array<[K, V]>()
  R.forEachObjIndexed((v, k) => _entries.push([k, v as any as V]), obj)
  return new Map(_entries)
}

/**
 * Create a Map from an array of objects, using the given function to generate keys from each element first.
 * In the case of duplicate keys, the last member to produce a key will be the only array member present in the Map.
 * The array members will be used as the values of the Map.
 */
export const fromArray = <K, V>(keyFunc: (value: V) => K, array: V[]): Map<K, V> => {
  return new Map(array.map(v => [keyFunc(v), v]))
}

/**
 * Create a Map from an array of objects, using the given function to generate keys from each element first.
 * In the case of duplicate keys, the last member to produce a key will be the only array member present in the Map.
 * The array members will be used as the values of the Map.
 */
export const fromValueArray = <K extends string | number | symbol, V>(
  keyFunc: (value: V) => K,
  array: V[]
): Map<K, V> => {
  return new Map(array.map(v => [keyFunc(v), v]))
}

/**
 * Create a Map from an array of objects, using the given function to generate values from each key first.
 * In the case of duplicate keys, the last function's value for a given key will be the only value present in the Map.
 * The array members will be used as the keys of the Map.
 */
export const fromKeyArray = <K extends string | number | symbol, V>(
  valFunc: (key: K) => V,
  array: K[]
): Map<K, V> => {
  return new Map(array.map(k => [k, valFunc(k)]))
}

/**
 * Splits a list into sublists stored in a Map, where the keys are generated by calling the given key-returning
 * function on each element, and the values are an array of list items that were given the same key by the function.
 */
export const groupBy = <K, V>(
  groupingFunc: (listItem: V) => K,
  list: ReadonlyArray<V>
): Map<K, V[]> => {
  const m = new Map<K, V[]>()

  list.forEach(li => {
    const k = groupingFunc(li)
    const existing = m.get(k)
    if (existing !== undefined) {
      m.set(k, [...existing, li])
    } else {
      m.set(k, [li])
    }
  })

  return m
}

/** Map.get() - get an item. */
export const get = <K, V>(key: K, m: Map<K, V>): V | undefined => {
  return m.get(key)
}

/** Like Map.get(), but can accept an undefined key for ease of use. */
export const maybeGet = <K, V>(key: K | undefined, m: Map<K, V>): V | undefined => {
  return key ? m.get(key) : undefined
}

/** Merges all the given Maps. Overlapping keys will take the last object in the parameter lists' value for that key */
export const merge = <K, V>(...maps: Map<K, V>[]): Map<K, V> => {
  const combinedMapEntries: [K, V][] = R.chain(m => entries(m), maps)
  return new Map<K, V>(combinedMapEntries)
}

/** Get an Array of [key, value] pairs from a given Map */
export const entries = <K, V>(m: Map<K, V>): Array<[K, V]> => {
  return Array.from(m.entries())
}

/** Get an Array of the values of a given Map */
export const values = <V>(m: Map<any, V>): Array<V> => {
  return Array.from(m.values())
}

/** Get an Array of the keys of a given Map */
export const keys = <K>(m: Map<K, any>): Array<K> => {
  return Array.from(m.keys())
}

/** Get a Set of the keys of a given Map */
export const keySet = <K>(m: Map<K, any>): Set<K> => {
  return new Set(m.keys())
}

/** Returns an array, where each array item is generated by the given function from the map's keys and values. */
export const mapEntries = <To, K, V>(
  func: (value: V, key: K, index: number) => To,
  m: Map<K, V>
): Array<To> => {
  return entries(m).map((entry: [K, V], i: number) => func(entry[1], entry[0], i))
}

/** Returns a new map, where each value is generated by the given function from the map's keys and values. */
export const map = <NewV, K, V>(
  func: (value: V, key: K, index: number) => NewV,
  m: Map<K, V>
): Map<K, NewV> => {
  const newM = new Map<K, NewV>()
  forEachIndexed((value, key, i) => {
    newM.set(key, func(value, key, i))
  }, m)
  return newM
}

/** Iterates over a given map, calling the given function with each (value, key, index) */
export const forEachIndexed = <K, V>(
  func: (value: V, key: K, index: number) => void,
  m: Map<K, V>
): void => {
  let count: number = 0
  m.forEach((value: V, key: K) => {
    func(value, key, count++)
  })
}

/* eslint-disable no-redeclare */
/** Return a subset of a Map, where a pair is included in the new Map iff the predicate returns true for it. */
function filter<K, V, V1 extends V>(
  predicate: (value: V, key: K) => value is V1,
  m: Map<K, V>
): Map<K, V1>
function filter<K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): Map<K, V>
function filter<K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): Map<K, V> {
  const filtered = new Map<K, V>()
  m.forEach((value: V, key: K) => {
    if (predicate(value, key)) {
      filtered.set(key, value)
    }
  })
  return filtered
}
/* eslint-enable no-redeclare */
export {filter}

/** Return a subset of a Map, where only keys in keepKeys are kept the new Map. */
export const keep = <K, V>(keepKeys: K | K[] | Set<K>, m: Map<K, V>): Map<K, V> => {
  const _set = keepKeys instanceof Set ? keepKeys : new Set(RA.ensureArray(keepKeys))
  return filter((v, k) => _set.has(k), m)
}

/** Return a subset of a Map, where a pair is excluded from the new Map iff the predicate returns true for it. */
export const reject = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): Map<K, V> => {
  return filter((v, k) => !predicate(v, k), m)
}

/** Return a subset of a Map, where any key in excludeKeys is removed from the new Map. */
export const omit = <K, V>(
  excludeKeys: K | K[] | Set<K>,
  m: Map<K, V>
): Map<K, V> => {
  const _set =
    excludeKeys instanceof Set ? excludeKeys : new Set(RA.ensureArray(excludeKeys))
  return reject((v, k) => _set.has(k), m)
}

type TPartitionReturnType<K, V, P extends keyof any> = {[k in P]: Map<K, V>}

/** Splits a map into 2 maps, based on the return value of the predicate for each entry. */
export const partition = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): TPartitionReturnType<K, V, TTrueOrFalse> => {
  return {
    true: new Map(),
    false: new Map(),
    ...partitionN((v, k) => '' + predicate(v, k), m),
  }
}

/** Splits a map into N maps, based on the return value of the predicate for each entry. */
export const partitionN = <K, V, P extends keyof any>(
  predicate: (value: V, key: K) => P,
  m: Map<K, V>
): Partial<TPartitionReturnType<K, V, P>> => {
  // TODO would be neat to use an ES6 Proxy object here to avoid the Partial
  //   the other option would be making the caller pass the total set of Ps, but that's kinda lame
  const retVal: Partial<Record<P, Map<K, V>>> = {}
  m.forEach((value: V, key: K) => {
    const p = predicate(value, key)
    const existing = retVal[p] ?? new Map<K, V>()
    retVal[p] = existing.set(key, value)
  })

  return retVal
}

/** Convert a Map<string, V> to a {string: V} JS object */
export const toStringObj = <V>(m: Map<string, V>): {[k: string]: V} => {
  return R.fromPairs(entries(m))
}

// https://stackoverflow.com/a/54180342/2544629
export const stringify = (m: Map<string | number | symbol, any>): string => {
  return JSON.stringify(m, (k, v) => (v instanceof Map ? [...values(v)] : v))
}

/** This method tries its best to convert an object (which may contain Maps/Arrays/POJOs) to a POJO recursively */
export const toObjRecursive = (obj: any): any => {
  if (obj instanceof Map) {
    if (typeof keys(obj)[0] === 'string') {
      // keys are strings, values are unknown. recurse
      const stringified = toStringObj(obj)
      const returnObj: any = {}
      R.mapObjIndexed((v, k) => {
        returnObj[k] = toObjRecursive(v)
      }, stringified)
      return returnObj
    } else if (empty(obj)) {
      return {}
    } else {
      throw new Error("Can't convert a non-string Map to a JS object.")
    }
  } else if (RA.isPlainObj(obj) || RA.isArray(obj)) {
    return R.map(toObjRecursive, obj)
  } else {
    return obj
  }
}

/** Returns the first value that the given predicate returns true for it & it's key. */
export const find = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): V | undefined => {
  const found = entries(m).find(([k, v]) => predicate(v, k))
  return found ? found[1] : undefined
}

/** This is how you clone a Map, clone a Map, clone a Map, early in the morning */
export const clone = <K, V>(m: Map<K, V>): Map<K, V> => new Map(m)

/** Returns true if any of the Map's pairs pass the given predicate. */
export const anyPass = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): boolean => {
  return find(predicate, m) !== undefined
}

/** Returns true if none of the Map's pairs pass the given predicate. */
export const nonePass = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): boolean => {
  return !anyPass(predicate, m)
}

/** Returns true if all of the Map's pairs pass the given predicate. */
export const allPass = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): boolean => {
  // "none of the predicates fail"
  /* prettier-ignore */
  return nonePass(R.compose(R.not, predicate), m)
}

/** Returns the value of the Map's first key-value pair (Maps are ordered by insertion order) */
export const firstValue = <V>(m: Map<unknown, V>): V | undefined => {
  const _firstEntry = firstEntry(m)
  return _firstEntry && _firstEntry[1]
}

/** Returns the value of the Map's first key-value pair (Maps are ordered by insertion order) */
export const firstKey = <K>(m: Map<K, unknown>): K | undefined => {
  const _firstEntry = firstEntry(m)
  return _firstEntry && _firstEntry[0]
}

/** Returns the value of the Map's first key-value pair (Maps are ordered by insertion order) */
export const firstEntry = <K, V>(m: Map<K, V>): [K, V] | undefined => {
  return entries(m)[0]
}

/** Is the map empty? */
export const empty = (m: Map<any, any>) => m.size === 0

/** Does the map have any members? */
export const nonempty = (m: Map<any, any>) => !empty(m)

/** Is the map defined and nonempty? */
export const present = (m: Map<any, any> | undefined) => !!m && nonempty(m)

/**
 * JS Maps are kept in insertion order, so they are ordered.
 * Reorder pairs via a list of comparators that receive the values of each key/value pair.
 */
export const sortValues = <K, V>(
  comparators: ReadonlyArray<ComparatorFunction<V>>,
  m: Map<K, V>
): Map<K, V> => {
  // given comparators only sort by value, but we need to get a list of [k,v] pairs out of the sort, so wrap them
  const sortedEntries: [K, V][] = R.sortWith<[K, V]>(
    comparators.map(func => {
      return (e1: [K, V], e2: [K, V]) => func(e1[1], e2[1])
    }),
    entries(m)
  )

  return new Map<K, V>(sortedEntries)
}

/**
 * JS Maps are kept in insertion order, so they are ordered.
 * Reorder pairs via a list of comparators that receive the keys of each key/value pair.
 */
export const sortKeys = <K, V>(
  comparators: ReadonlyArray<ComparatorFunction<K>>,
  m: Map<K, V>
): Map<K, V> => {
  // given comparators only sort by key, but we need to get a list of [k,v] pairs out of the sort, so wrap them
  const sortedEntries: [K, V][] = R.sortWith<[K, V]>(
    comparators.map(func => {
      return (e1: [K, V], e2: [K, V]) => func(e1[0], e2[0])
    }),
    entries(m)
  )

  return new Map<K, V>(sortedEntries)
}

/**
 * JS Maps are kept in insertion order, so they are ordered.
 * Reorder pairs via a list of comparators that receive the values of each key/value pair.
 */
export const sortEntries = <K, V>(
  comparators: Array<ComparatorFunction<[K, V]>>,
  m: Map<K, V>
): Map<K, V> => {
  const sortedEntries: [K, V][] = R.sortWith<[K, V]>(comparators, entries(m))
  return new Map<K, V>(sortedEntries)
}

export type ComparatorFunction<T> = (a: T, b: T) => number

/**
 * Returns a new map, where the new entries are added to the given Map.
 * In the case of key collisions, entries in the given Map will be overwritten by the new entries.
 */
export const addEntries = <K, V>(
  newEntries: Array<[K, V]>,
  m: Map<K, V>
): Map<K, V> => {
  return new Map([...entries(m), ...newEntries])
}

/** Sets the given key/value on a `clone` of the given Map. Unlike Map.prototype.set(), this doesn't mutate the Map. */
export const set = <K, V>(key: K, value: V, m: Map<K, V>): Map<K, V> => {
  return clone(m).set(key, value)
}

/** Sets the given key/value on a `clone` of the given Map if the key is not set already. */
export const defaultTo = <K, V>(key: K, value: V, m: Map<K, V>): Map<K, V> => {
  return m.has(key) ? m : set(key, value, m)
}

/** Sets the given keys to the given value on a `clone` of the given Map for each key that is not set already. */
export const defaultAllTo = <K, V>(ks: K[], value: V, m: Map<K, V>): Map<K, V> => {
  const defaults = new Map(ks.map(k => [k, value]))
  return merge(defaults, m)
}
