import React from 'react'
import {TNewUserParams} from '~/api/generated/types/AdminNewUser'
import FormSubmissionButton from '~/components/ProvideForm/FormSubmissionButton'
import ProvideForm from '~/components/ProvideForm/normal'
import {notEmpty} from '~/components/ProvideForm/utils'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import styles from './styles.module.css'

interface IProps {
  createNewUser: (newUserParams: TNewUserParams) => Promise<any>
}

interface IState {}

export default class AdminNewUserUI extends React.Component<IProps, IState> {
  state: IState = {}

  renderForm = () => (
    <ProvideForm<TNewUserParams, void>
      onSubmit={formState =>
        this.props
          .createNewUser(formState)
          .then(() => window.location.reload())
          .catch(() => alert('Failed to create new user'))
      }
      toFormFields={(generateFormFieldProps, formProps) => (
        <div>
          <LabeledLineTextInput
            formFieldProps={generateFormFieldProps('name', [notEmpty])}
            label="Coworker Name"
            placeholder="Charlie Tuna"
            inputType="text"
          />
          <LabeledLineTextInput
            formFieldProps={generateFormFieldProps('emailPrefix', [notEmpty])}
            label={`Email Prefix (the characters before "@basis.co")`}
            placeholder="charliet"
            inputType="text"
          />
          <FormSubmissionButton
            formProps={formProps}
            buttonStyle="primary"
            size="medium"
            className={styles.submitButton}
          >
            Submit
          </FormSubmissionButton>
        </div>
      )}
    />
  )

  render() {
    return (
      <div className={styles.formContainer}>
        <h1>Add New User</h1>
        {this.renderForm()}
      </div>
    )
  }
}
