import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'
import {TRegisterPersonParams} from '~/api/generated/types/RegisterPerson'

export default class RegisterPerson extends PostApi<
  TRegisterPersonParams,
  TPeopleDataResponse
> {
  protected getMockResponse(params: TRegisterPersonParams): TPeopleDataResponse {
    return {
      people: [
        {
          id: 'person-0',
          name: 'Bob',
        },
        {
          id: 'person-1',
          name: params.name,
        },
      ],
    }
  }

  protected getPath(pathPieces: {}) {
    return serverEndpoints.weatherRegisterPerson()
  }

  protected transformResponse(r: TPeopleDataResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
