import * as React from 'react'
import {connect} from 'react-redux'
import {Navigate} from 'react-router'
import environment from '~/config/environment'
import {ReduxState} from '~/redux/reducers/root'
import {staticURLs} from '~/routing/sitemap'
import LedgerETLFEM from './LedgerETLFEM'

interface IReduxProps extends Pick<ReduxState, 'userState'> {}

class HomePage extends React.Component<IReduxProps> {
  render() {
    const signedIn = !!this.props.userState.user
    return (
      <div>
        {environment.appName === 'Basis' && <Navigate to={staticURLs.auth.signin} />}
        {environment.appName === 'LedgerETL' && <LedgerETLFEM />}

        {/* TODO: Pretty sure this signedIn check is redundant with HomeLayout? */}
        {signedIn ? (
          <Navigate to={staticURLs.dashboard} />
        ) : (
          // <Navigate to={staticURLs.auth.signin} />
          <></>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  userState: state.userState,
})

export default connect(mapStateToProps)(HomePage)
