import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {
  TCreateMappingParams,
  TListMappingsResponse,
} from '~/api/generated/types/CreateMapping'
import {ListMappingsMockResponse} from './ListMappings'

export default class CreateMapping extends PostApi<
  TCreateMappingParams,
  TListMappingsResponse
> {
  protected getMockResponse(params: TCreateMappingParams): TListMappingsResponse {
    return {
      mappingResponse: ListMappingsMockResponse.mappingResponse.concat([
        {
          mappingId: 'create-mapping-params-response-mapping-0',
          mappingName: params.createMappingName,
          mappingFields: params.createMappingFields,
        },
      ]),
    }
  }

  protected getPath(pathPieces: {organizationId: string}) {
    return serverEndpoints.financeCreateMapping(pathPieces)
  }

  protected transformResponse(r: TListMappingsResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
