import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TGeneratePasswordResetCodeRequest} from '~/api/generated/types/common'

export default class GenerateResetPasswordCode extends PostApi<
  TGeneratePasswordResetCodeRequest,
  {}
> {
  protected getMockResponse(params: TGeneratePasswordResetCodeRequest): {} {
    return {}
  }

  protected getPath(pathPieces: {}) {
    return serverEndpoints.generateResetPasswordCode()
  }

  protected transformResponse(r: {}) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
