// suggestion: use S as an import, i.e.:
// import * as S from '~/utils/Set'

import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import {safelyOr} from '~/utils'

/** Create a new Set from a given Set */
export const clone = <V>(s: Set<V>): Set<V> => new Set(s)

export type SetCreationParameter<K> = NonNullable<K | K[] | Set<K>>
/** Get a new set back from one, many, or a Set of items */
export const create = <K>(items: SetCreationParameter<K>): Set<K> => {
  return items instanceof Set ? clone(items) : new Set(RA.ensureArray(items))
}

export const createOrEmpty = <K>(
  items: SetCreationParameter<K> | null | undefined
): Set<K> => {
  return safelyOr(items, create, new Set())
}

/** Get an Array of the values of a given Set */
export const values = <V>(s: Set<V>): Array<V> => {
  return Array.from(s.values())
}

/** Return a subset of a Set, where a pair is included in the new Set iff the predicate returns true for it. */
export const filter = <V>(predicate: (value: V) => boolean, s: Set<V>): Set<V> => {
  const filtered = new Set<V>()
  s.forEach((value: V) => {
    if (predicate(value)) {
      filtered.add(value)
    }
  })
  return filtered
}

/** Return a subset of a Set, where a value is excluded from the new Set iff the predicate returns true for it. */
export const reject = <V>(predicate: (value: V) => boolean, s: Set<V>): Set<V> => {
  return filter(v => !predicate(v), s)
}

/** Returns an array, where each array item is generated by the given function from the set's values. */
export const map = <To, V>(
  func: (value: V, index: number) => To,
  s: Set<V>
): Array<To> => {
  return values(s).map(func)
}

/** Returns an array, where each array item is generated by the given function from the set's values. */
export const forEach = <To, V>(
  func: (value: V, index: number) => To,
  s: Set<V>
): void => {
  values(s).forEach(func)
}

/** Returns a Set, where each item is generated by the given function from the set's values. */
export const mapSet = <To, V>(
  func: (value: V, index: number) => To,
  s: Set<V>
): Set<To> => {
  return new Set(values(s).map(func))
}

/** Returns a Map, where each member of the Set is a key, and each value is generated with the given function. */
export const toMap = <K, V>(
  makeValue: (key: K, index: number) => V,
  s: Set<K>
): Map<K, V> => {
  return new Map(map((k, i) => [k, makeValue(k, i)], s))
}

export const reduce = <V, A>(
  reducer: (acc: A, v: V, idx: number) => A,
  s: Set<V>,
  initialValue: A
): A => {
  let a: A = initialValue
  forEach((v, idx) => (a = reducer(a, v, idx)), s)
  return a
}

/** Sets are iterated in insertion order. This method returns the first-inserted element of the Set. */
export const first = <V>(s: Set<V>): V | undefined => {
  return values(s)[0]
}

/** Returns the intersection between two sets. The order of the first set is preserved. */
export const intersection = <V>(s1: Set<V>, s2: Set<V>): Set<V> => {
  const i = new Set<V>()
  s1.forEach(v => {
    if (s2.has(v)) {
      i.add(v)
    }
  })
  return i
}

/** Returns true iff the intersection between two sets is nonempty */
export const intersects = <V>(s1: Set<V>, s2: Set<V>): boolean => {
  return nonempty(intersection(s1, s2))
}

/** Checks if the two sets have exactly the same members (regardless of insertion order) */
export const equal = (s1: Set<any>, s2: Set<any>): boolean => {
  if (s1.size !== s2.size) {
    return false
  }

  return subset(s1, s2)
}

/** Returns a subset of a Set, where the first (in insertion order) value has been removed (if nonempty). */
export const pop = <V>(
  s: Set<V>,
  popppedValueCallback?: (value: V | undefined) => void
): Set<V> => {
  const firstElement = first(s)
  popppedValueCallback?.(firstElement)
  return firstElement ? toggleMembership(firstElement, s) : s
}

export const empty = (s: Set<any>) => s.size === 0
export const nonempty = (s: Set<any>) => !empty(s)
export const present = (s: Set<any> | undefined) => !!s && nonempty(s)

export const toggleMembership = <T>(value: T, set: Set<T>): Set<T> => {
  return setMembership(!set.has(value), value, set)
}

// Return a copy of the set, with the specified element added/removed as specified
export const setMembership = <T>(
  isMember: boolean,
  value: T,
  set: Set<T>
): Set<T> => {
  const copy = clone(set)
  if (isMember) {
    copy.add(value)
  } else {
    copy.delete(value)
  }
  return copy
}

/* Add an element to a Set. Unlike Set.prototype.add, this does not mutate the given Set. */
export const add = <V>(elementToAdd: V, s: Set<V>): Set<V> => {
  const dupe = clone(s)
  return dupe.add(elementToAdd)
}

/* Remove an element from a Set. Unlike Set.prototype.delete, this does not mutate the given Set. */
export const remove = <V>(elementToRemove: V, s: Set<V>): Set<V> => {
  const dupe = clone(s)
  dupe.delete(elementToRemove)
  return dupe
}

/** Returns whether the first set is a subset of the second. */
export const subset = <T>(set1: Set<T>, set2: Set<T>): boolean => {
  return values(set1).every(v => set2.has(v))
}

/** Combine all given Set entries into one Set. */
export const union = <T>(
  // TODO: should be `ReadonlyArray<Set<T>>` but TS complains that's not an array type. Maybe TS will fix this soon.
  //  also, could make this also accept a plain array as well, but that takes some extra work:
  //  https://www.stevefenton.co.uk/2013/11/allowing-array-or-rest-parameters-using-overloads-in-typescript/
  ...sets: Set<T>[]
): Set<T> => {
  const allVals = R.reduce<Set<T>, T[]>(
    (acc, nextSet) => acc.concat(values(nextSet)),
    [],
    sets
  )
  return new Set<T>(allVals)
}

/** A - B */
export const difference = <T>(A: Set<T>, B: Set<T>): Set<T> => {
  // "reject everything that B has from A"
  return reject(elem => B.has(elem), A)
}

/**
 *  Like Set.has(), but returns `val is T` so you can assert that, if the value
 *  is in the set, it is indeed of the Set's value type
 */
export const has = <T>(val: any, s: Set<T>): val is T => {
  return s.has(val)
}

/**
 *  Like Set.has(), but can accept an undefined or incorrectly-typed key for ease of use.
 *  Also returns `val is T` so you can assert that, if the value is in the set,
 *  it is indeed of the Set's value type
 */
export const maybeHas = <T>(val: any | undefined, s: Set<T>): val is T => {
  return val !== undefined ? has(val, s) : false
}

/** Returns the first value that the given predicate returns true for, if any */
export const find = <T>(
  predicate: (value: T) => boolean,
  s: Set<T>
): T | undefined => {
  return values(s).find(predicate)
}

/** Returns true if any of the Set's values pass the given predicate. */
export const anyPass = <T>(predicate: (value: T) => boolean, s: Set<T>): boolean => {
  return find(predicate, s) !== undefined
}

/** Returns true if none of the Set's values pass the given predicate. */
export const nonePass = <T>(
  predicate: (value: T) => boolean,
  s: Set<T>
): boolean => {
  return !anyPass(predicate, s)
}

/** Returns true if all of the Set's values pass the given predicate. */
export const allPass = <T>(predicate: (value: T) => boolean, s: Set<T>): boolean => {
  // "none of the predicates fail"
  /* prettier-ignore */
  return nonePass(R.compose(R.not, predicate), s)
}
