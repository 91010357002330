// Autogenerated, edits will not be preserved

export const serverEndpoints = {
  // GET
  robots(): string {
    return `/robots.txt`
  },

  // GET
  home(): string {
    return `/`
  },

  // POST
  signup(): string {
    return `/signup`
  },

  // POST
  signin(): string {
    return `/signin`
  },

  // POST
  logout(): string {
    return `/logout`
  },

  // POST
  generateResetPasswordCode(): string {
    return `/generate-reset-password-code`
  },

  // POST
  resetPassword(): string {
    return `/reset-password`
  },

  // GET
  getInitialData(): string {
    return `/get-initial-data`
  },

  // GET
  dashboardData(): string {
    return `/dashboard-data`
  },

  // POST
  adminAdminNewUser(): string {
    return `/admin/new-user`
  },

  // POST
  weatherRegisterPerson(): string {
    return `/weather/register`
  },

  // POST
  weatherEditPersonDetails(pathPieces: {personId: string}): string {
    return `/weather/edit-details/${pathPieces.personId}`
  },

  // POST
  weatherDeletePerson(pathPieces: {personId: string}): string {
    return `/weather/delete-person/${pathPieces.personId}`
  },

  // POST
  weatherGetPeopleData(): string {
    return `/weather/people`
  },

  // POST
  financeCreateMapping(pathPieces: {organizationId: string}): string {
    return `/finance/${pathPieces.organizationId}/create-mapping`
  },

  // POST
  financeConvert(pathPieces: {organizationId: string}): string {
    return `/finance/${pathPieces.organizationId}/convert`
  },

  // POST
  financeListMappings(pathPieces: {organizationId: string}): string {
    return `/finance/${pathPieces.organizationId}/list`
  },
}
