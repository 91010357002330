export const dynamicURLs = {}

export const staticURLs = {
  home: '/',

  admin: {
    index: '/admin',
    newUser: '/admin/new-user',
    signups: '/admin/sign-ups',
  },

  auth: {
    signin: '/sign-in',
    signout: '/sign-out',
    signup: '/sign-up',
  },

  dashboard: '/dashboard',

  plants: '/plants',

  weather: '/weather',

  finance: '/finance',

  settings: {
    index: '/settings',
  },
}
