import * as React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import LoadingScreen, {TLoadingState} from '~/components/LoadingScreen'
import * as utils from '~/pages/utils'
import {updateGlobalDataFromServer} from '~/redux/massUpdate'
import AppRoutes from '~/routing/routes'

interface IProps {}
interface IState {
  loadState: TLoadingState
}

import {ThemeOptions, ThemeProvider, createTheme} from '@mui/material/styles'

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#3693b3',
    },
    secondary: {
      main: '#b35536',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    success: {
      main: '#36b393',
    },
  },
}

export default class SinglePageApplication extends React.Component<IProps, IState> {
  state: IState = {
    loadState: 'loading',
  }

  render() {
    return (
      <HelmetProvider>
        <ThemeProvider theme={createTheme(themeOptions)}>
          <AppRoutes />
        </ThemeProvider>
      </HelmetProvider>
    )
  }
}
