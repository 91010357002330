import * as React from 'react'
import api from '~/api/endpoints'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import {useAuth} from '~/hooks/useAuth'
import DashboardUI from '~/pages/Dashboard/stateless'

interface IProps {}

interface IState {
  dashboardData: TDashboardDataResponse
}
export default function DashboardPage(props: IProps, state: IState) {
  const [dashboardData, setDashboardData] =
    React.useState<null | TDashboardDataResponse>(null)

  const user = useAuth()

  return (
    <api.dashboard.PromiseManager
      requestParams={{}}
      pathPieces={{}}
      onSuccess={data => setDashboardData(data)}
      then={() => (
        <>
          {dashboardData && (
            <DashboardUI user={user} dashboardData={dashboardData} />
          )}
        </>
      )}
    />
  )
}
