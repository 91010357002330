import * as React from 'react'
import styles from './styles.module.css'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'
import {TRegisterPersonParams} from '~/api/generated/types/RegisterPerson'
import DSModalForm from '~/design-system/Modals/Form'
import ProvideForm from '~/components/ProvideForm/normal'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import Spacer from '~/components/Spacer'
import {notEmpty} from '~/components/ProvideForm/utils'
import DSButton from '~/design-system/Button'
import TableContainer from '@mui/material/TableContainer'
import {Paper, Table, TableRow, Typography} from '@mui/material'
import WeatherRenderRow from '../WeatherRowMenu'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import {useSelector} from 'react-redux'
import {WeatherState, setDashboardData} from '~/redux/reducers/weather'
import {ReduxState} from '~/redux/reducers/root'
import {connect} from 'react-redux'
import store from '~/redux/store'

interface ReduxProps extends Pick<WeatherState, 'dashboardData'> {}

interface IProps extends ReduxProps {
  dashboardData: TPeopleDataResponse
  registerNewPerson: (
    registerPersonParams: TRegisterPersonParams
  ) => Promise<TPeopleDataResponse>
  deletePerson: (personId: string) => Promise<TPeopleDataResponse>
  editPersonDetails: (
    registerPersonParams: TRegisterPersonParams,
    personId: string
  ) => Promise<TPeopleDataResponse>
}

interface IState {
  addNewModal: boolean
  // currentDashboardData: TPeopleDataResponse
}

class WeatherTable extends React.Component<IProps, IState> {
  state = {
    addNewModal: false,
    // currentDashboardData: this.props.dashboardData,
  }

  // currentDashboardData = useSelector((state: any) => state.weather.dashboardData)

  renderAddNew = () => {
    return (
      <TableRow
        className={styles.weatherTableRow}
        onClick={() => this.setState({addNewModal: true})}
      >
        {/* <Typography variant="body1">+ add new</Typography> */}
        <PersonAddAltIcon fontSize="large" />
      </TableRow>
    )
  }

  renderAddNewModal = () => {
    return (
      <DSModalForm
        title="Add New"
        showCloseIcon={true}
        onRequestClose={() => this.setState({addNewModal: false})}
        over300PxTall={false}
      >
        <Typography variant="body1">Hi</Typography>

        <ProvideForm<TRegisterPersonParams, TPeopleDataResponse>
          onSubmit={this.props.registerNewPerson}
          wrapperClassName={styles.formWrapper}
          submitSuccess={(_, r) => {
            this.setState({addNewModal: false})
            store.dispatch(setDashboardData(r))
          }}
          toFormFields={(generateFormFieldProps, formProps) => {
            return (
              <div>
                <LabeledLineTextInput
                  label="Name"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps('name', [notEmpty], {})}
                  autoFocus={true}
                />

                <Spacer height="40" />

                <DSButton
                  buttonStyle="primary"
                  size="large"
                  onClick={() => formProps.onSubmit()}
                  loading={formProps.isFormSubmitting}
                  disabled={formProps.isFormSubmitting}
                  appearDisabled={formProps.anyErrors}
                >
                  Add New
                </DSButton>
              </div>
            )
          }}
        />
      </DSModalForm>
    )
  }

  render() {
    return (
      <TableContainer component={Paper}>
        <Table className={styles.weatherTableWrapper}>
          {this.props.dashboardData.people.map(row => (
            <WeatherRenderRow
              row={row}
              deletePerson={this.props.deletePerson}
              editPersonDetails={this.props.editPersonDetails}
            />
          ))}
          {this.renderAddNew()}
          {this.state.addNewModal && this.renderAddNewModal()}
        </Table>
      </TableContainer>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  dashboardData: state.weatherState.dashboardData,
})

export default connect(mapStateToProps)(WeatherTable)
