import * as React from 'react'
import api from '~/api/endpoints'
import SignUpUI from './stateless'
import {TSignInParams} from '~/api/endpoints/auth/SignIn'
import {staticURLs} from '~/routing/sitemap'
import {massUpdateRedux} from '~/redux/massUpdate'
import {TSignUpResponse} from '~/api/endpoints/auth/SignUp'
import {useNavigate} from 'react-router'

export default function SignUpPage() {
  const navigate = useNavigate()

  const loginComplete = (response: TSignUpResponse) => {
    massUpdateRedux(response, 'signin')
    navigate(staticURLs.dashboard)
  }

  return (
    <SignUpUI
      signup={(request: TSignInParams) =>
        api.auth.signUp.post(request, {}).then(loginComplete)
      }
    />
  )
}
