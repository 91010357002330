import * as React from 'react'
import DSLink from '~/design-system/Link'
import User from '~/models/app/User'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import {DSTextDiv} from '~/design-system/DSText'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps {
  dashboardData: TDashboardDataResponse
  user: User
}

interface IState {}

export default class DashboardUI extends React.Component<IProps, IState> {
  renderCard(title: string, link: string) {
    return (
      <DSLink underline="never" className={styles.link} to={link}>
        <DSTextDiv size="body-15" className={styles.card}>
          {title}
        </DSTextDiv>
      </DSLink>
    )
  }

  render() {
    return (
      <div className={styles.dashboardWrapper}>
        <DSTextDiv size="h1" className={styles.header}>
          Dashboard
        </DSTextDiv>
        <div className={styles.dashboardGrid}>
          {this.renderCard('Plants', staticURLs.plants)}
        </div>

        <div className={styles.dashboardGrid}>
          {this.renderCard('Weather', staticURLs.weather)}
        </div>

        <div className={styles.dashboardGrid}>
          {this.renderCard('Finance', staticURLs.finance)}
        </div>

        <DSTextDiv size="body-17" className={styles.inspiration}>
          A basis for many things to come.
        </DSTextDiv>
      </div>
    )
  }
}
