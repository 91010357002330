// stops redux from complaining about our use of Map in our stores
// https://github.com/reduxjs/redux-toolkit/issues/466
// https://stackoverflow.com/a/67220847/2544629
import {enableMapSet} from 'immer'
enableMapSet()

import * as Redux from 'redux'
import store from '~/redux/store'
import authReducer, {AuthState} from '~/redux/reducers/auth'
import userReducer, {UserState, clearUser} from './user'
import settingsReducer, {SettingsState, resetSettings} from './settings'
import weatherReducer, {WeatherState, resetWeather} from './weather'
import financeReducer, {FinanceState, resetFinance} from './finance'
import {batchActions} from 'redux-batched-actions'

export type ReduxState = {
  authState: AuthState
  settingsState: SettingsState
  userState: UserState
  weatherState: WeatherState
  financeState: FinanceState
}

const createReducers = (): Record<keyof ReduxState, Redux.Reducer<any, any>> => ({
  authState: authReducer,
  settingsState: settingsReducer,
  userState: userReducer,
  weatherState: weatherReducer,
  financeState: financeReducer,
})

const createAppReducer = () => Redux.combineReducers(createReducers())

export const ROOT_LOGOUT_ACTION_KEY = '_ROOT_LOGOUT'

export const createRootReducer =
  () =>
  (
    state: ReduxState | undefined,
    action: any // Redux.AnyAction
  ) => {
    if (action.type === ROOT_LOGOUT_ACTION_KEY) {
      // Set the state to undefined on logout so everything is reset
      return createAppReducer()(undefined, action)
    }

    return createAppReducer()(state, action)
  }

export const logoutRedux = () => {
  store.dispatch({type: ROOT_LOGOUT_ACTION_KEY})
}
