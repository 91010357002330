import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

interface IProps {
  text: string
}

export function QuestionTooltip(props: IProps) {
  return (
    <Tooltip title={props.text}>
      <HelpOutlineIcon fontSize="small" />
    </Tooltip>
  )
}
