import * as FontAwesome from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import DSText from '~/design-system/DSText'
import DSModal, {IModalBaseProps} from '~/design-system/Modals/DSModal'
import styles from './styles.module.css'

export interface IModalFormProps extends IModalBaseProps, React.PropsWithChildren {
  title: string
  /** If FontAwesome props are present an icon will be added in front of the title
   * A default color is supplied by the Modal—all other props you want should be specified.
   */
  iconProps?: FontAwesome.FontAwesomeIconProps
  showCloseIcon?: boolean
}

/** Use this modal when you need to embed a form in it.
 * Provide the title/icon via props
 * Because the "body" and buttons of the form vary, those need to be provided via children.
 * Because it varies by form, you are responsible for setting the margin-top/bottom of your form
 * (To separate the title from the children)
 * The DSModalButtonContainer can be used to conveniently layout the buttons
 */
export default class DSModalForm extends React.Component<IModalFormProps> {
  render() {
    const {
      title,
      iconProps,
      showCloseIcon,
      children,
      containerClass,
      ...modalProps
    } = this.props
    return (
      <DSModal {...modalProps} containerClass={containerClass ?? styles.card}>
        {showCloseIcon && (
          <img
            src={'/icons/close-currentColor.svg'}
            className={styles.closeIcon}
            onClick={this.props.onRequestClose}
          />
        )}
        <div className={styles.titleContainer}>
          {iconProps && <FontAwesome.FontAwesomeIcon {...iconProps} />}
          <DSText
            className={classnames(
              styles.title,
              iconProps && styles['title-with-icon']
            )}
            size="section-title"
          >
            {title}
          </DSText>
        </div>
        {children}
      </DSModal>
    )
  }
}
