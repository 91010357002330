import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TPasswordResetRequest} from '~/api/generated/types/common'

export default class ResetPassword extends PostApi<TPasswordResetRequest, {}> {
  protected getMockResponse(params: TPasswordResetRequest): {} {
    return {}
  }

  protected getPath(pathPieces: {}) {
    return serverEndpoints.resetPassword()
  }

  protected transformResponse(r: {}) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
