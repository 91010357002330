import * as QueryString from 'query-string'
import * as React from 'react'
import {safely} from '~/utils/index'

export type CSSSpacingProperties = Pick<
  React.CSSProperties,
  | 'padding'
  | 'paddingTop'
  | 'paddingRight'
  | 'paddingLeft'
  | 'paddingBottom'
  | 'margin'
  | 'marginTop'
  | 'marginRight'
  | 'marginLeft'
  | 'marginBottom'
>

export type FlexJustifyContent = 'flex-start' | 'center' | 'flex-end'

const splitSearchString = (
  stringToBold: string,
  searchIndex?: number,
  queryText?: string
) => {
  let firstPart: string = stringToBold
  let boldedPart: string = ''
  let lastPart: string = ''
  if (!!queryText && searchIndex !== undefined) {
    firstPart = stringToBold.slice(0, searchIndex)
    boldedPart = stringToBold.slice(searchIndex, searchIndex + queryText.length)
    lastPart = stringToBold.slice(searchIndex + queryText.length)
  }

  return {firstPart, boldedPart, lastPart}
}

export const boldedSearchString = (
  stringToBold: string,
  searchIndex?: number,
  queryText?: string
) => {
  const {firstPart, boldedPart, lastPart} = splitSearchString(
    stringToBold,
    searchIndex,
    queryText
  )
  return [firstPart, <b key="bold">{boldedPart}</b>, lastPart]
}

export const stringFromPartial =
  <T extends any>(queryObject: T) =>
  <K extends keyof T>(key: K): string | undefined => {
    const value: any | undefined = queryObject[key]
    if (typeof value === 'string') {
      return value
    }
    return undefined
  }

export const numberFromPartial =
  <T extends any>(queryObject: T) =>
  <K extends keyof T>(key: K): Extract<T[K], number> | undefined => {
    const value: any | undefined = queryObject[key]
    if (typeof value === 'number' && !isNaN(value)) {
      return value as Extract<T[K], number>
    }
    return undefined
  }

export const booleanFromPartial =
  <T extends any>(queryObject: T) =>
  <K extends keyof T>(key: K): Extract<T[K], boolean> | undefined => {
    const value: any | undefined = queryObject[key]
    if (typeof value === 'boolean') {
      return value as Extract<T[K], boolean>
    }
    return undefined
  }

export const numberFromQueryStringObj =
  <T extends any>(queryObject: T) =>
  <K extends keyof T>(key: K): number | undefined => {
    const value = safely(queryObject[key], Number)
    if (value !== undefined && !isNaN(value)) {
      return value
    }
    return undefined
  }

export const booleanFromQueryStringObj =
  <T extends any>(queryObject: T) =>
  <K extends keyof T>(key: K): boolean | undefined => {
    return (queryObject[key] as any) === 'true'
  }

export const isAtEndOfScroll = (element: HTMLDivElement): boolean => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
  return element.scrollHeight - Math.abs(element.scrollTop) === element.clientHeight
}

export const scrollToBottom = (element: HTMLDivElement): void => {
  // https://stackoverflow.com/questions/7303948/how-to-auto-scroll-to-end-of-div-when-data-is-added
  element.scrollTop = element.scrollHeight
}
