import * as React from 'react'
import {Navigate, useOutlet} from 'react-router-dom'
import environment from '~/config/environment'
import {useAuth} from '~/hooks/useAuth'
import {staticURLs} from '~/routing/sitemap'
import {safeUnreachableCase} from '~/utils'

const redirectSite: () => string = () => {
  switch (environment.appName) {
    case 'Basis':
      return staticURLs.dashboard
    case 'LedgerETL':
      return staticURLs.finance
    default:
      return safeUnreachableCase(environment.appName, staticURLs.dashboard)
  }
}

// This layout exists to redirect logged in users back to the dashboard
export const HomeLayout = () => {
  const {user} = useAuth()
  const outlet = useOutlet()

  // useAuth may return an empty user object here, so explicitly check for id instead of just the user object
  if (!!user?.id) {
    return <Navigate to={redirectSite()} replace />
  }

  return (
    <div>
      {/* TODO: Definitely needs FEM top bar when that page is built */}
      {outlet}
    </div>
  )
}
