import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TPeopleDataResponse} from '~/api/generated/types/GetPeopleData'

export default class GetPeopleData extends PostApi<{}, TPeopleDataResponse> {
  protected getMockResponse(params: {}): TPeopleDataResponse {
    return {
      people: [
        {
          id: 'person-0',
          name: 'Bob',
        },
      ],
    }
  }

  protected getPath(pathPieces: {}) {
    return serverEndpoints.weatherGetPeopleData()
  }

  protected transformResponse(r: TPeopleDataResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
