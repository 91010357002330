import {TGetInitialDataResponse} from '~/api/generated/types/GetInitialData'

const globalDataMock: TGetInitialDataResponse = {
  userDetails: {
    id: 'fake-user-id',
    username: 'jkeuhlen',
    email: 'me@jkeuhlen.com',
    admin: true,
    organizationId: 'fake-org-id',
  },
}

export default globalDataMock
