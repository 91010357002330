import * as React from 'react'
import api from '~/api/endpoints'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import {useAuth} from '~/hooks/useAuth'
import PlantsUI from '~/pages/Plants/stateless'

interface IProps {}

interface IState {
  dashboardData: TDashboardDataResponse
}

export default function PlantsPage(props: IProps) {
  const [dashboardData, setDashboardData] =
    React.useState<null | TDashboardDataResponse>(null)

  const user = useAuth()

  return (
    <api.dashboard.PromiseManager
      requestParams={{}}
      pathPieces={{}}
      onSuccess={data => setDashboardData(data)}
      then={() => (
        <>
          {dashboardData && <PlantsUI user={user} dashboardData={dashboardData} />}
        </>
      )}
    />
  )
}
