import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import {TSignInParams} from '~/api/endpoints/auth/SignIn'
import {redirect} from 'react-router-dom'
import {staticURLs} from '~/routing/sitemap'
import Footer from '~/components/Footer'
import {Paper} from '@mui/material'
import {NavLink} from 'react-router-dom'
import {LogoContainer} from '~/components/Footer/styles'
import {SvgIcon} from '~/common/SvgIcon'

interface IProps {
  signup: (request: TSignInParams) => Promise<any>
}

interface IState {
  email?: string
  password?: string
}

export default function SignUp(props: IProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const pass = data.get('password')
    if (!!email && !!pass) {
      props
        .signup({
          email: email.toString(),
          password: pass.toString(),
        })
        .then(r => {
          // redirect to home after signing up
          return redirect(staticURLs.dashboard)
        })
        .finally(() => {
          // errors?
        })
    } else {
      console.error('no email or password')
    }
  }

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <NavLink to="/">
            <LogoContainer>
              <SvgIcon
                src="logo.svg"
                aria-label="homepage"
                width="101px"
                height="64px"
              />
            </LogoContainer>
          </NavLink>
          <Typography component="h1" variant="h5">
            Create your account
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href={staticURLs.auth.signin} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
