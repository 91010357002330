import React from 'react'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import DSLink from '~/design-system/Link'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps {}

export default class AdminIndexPage extends React.Component<IProps> {
  renderLink(title: string, link: string) {
    return (
      <DSTextDiv size="body-15">
        <DSLink underline="onHover" to={link}>
          {title}
        </DSLink>
      </DSTextDiv>
    )
  }

  render() {
    return (
      <div className={styles.adminIndexWrapper}>
        <DSText size="h2" tag="h2">
          Admin Dashboard
        </DSText>
        {this.renderLink('Add New User', staticURLs.admin.newUser)}
      </div>
    )
  }
}
