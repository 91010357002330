import * as R from 'ramda'
import * as React from 'react'
import APIFormError from '~/api/client/APIError/APIFormError'
import APIInvalidArgumentsError from '~/api/client/APIError/APIInvalidArgumentsError'
import APIError from '~/api/client/APIError/base'
import {
  IFormPropsIncrementalFromBaseForm,
  TProvideFormOnSubmitIncremental,
  TProvideFormToFormFieldsIncrementalForBaseForm,
} from '~/components/ProvideForm/incremental'
import {
  TProvideFormOnSubmitNormal,
  TProvideFormToFormFieldsNormal,
} from '~/components/ProvideForm/normal'
import {isPresent, purgeUndefined, trim} from '~/utils'
import * as S from '~/utils/Set'
import styles from './styles.module.css'
import {
  AdvancedConstraint,
  basicToAdvanced,
  TAdvancedConstraintFailureResponse,
  TConstraint,
  TFormConstraint,
} from './utils'
import {IFormFieldProps, IFormProps, IFormPropsBase} from './types'

export type TSubmitFailureHandler<FormFieldsType extends IFieldValues> = (
  formState: FormFieldsType,
  error: unknown
) => void

// Used as the object that FormFieldsType extends from
export interface IFieldValues {
  [fieldName: string]: string
}

export type IFieldState = {
  value: string
  localErrorMessage: string | null
  serverErrorMessage: string | null
  /**
   * shouldShowErrorMessage is used to save that either:
   *   a) the user tried to submit the form or
   *   b) they entered the field and then left it.
   *
   * This allows us to decide whether to set the `errorMessage`,
   * since most fields would otherwise show errors immediately
   * when they are empty.
   */
  shouldShowErrorMessage: boolean
  /**
   * This allows us to track whether the field was set by the user
   * or is tracking the state of `initialValue`
   * This allows the field to reflect changes to `initialValue`
   * even after an error message is set
   */
  isDefaultValue: boolean

  /**
   * The value the server already has for this field.
   */
  submittedValue: string
  /**
   * How many times the field is in the process of being submitted to the server.
   * Useful for ProvideFormIncremental; ProvideFormNormal has this for the whole form
   * via IFormPropsBase.isFormSubmitting (not currently set for ProvideFormNormal)
   *
   * NB: used to be a boolean but changed to a counter to avoid a race condition where
   * a field was being submitted twice and the first submission would set `isSubmitting` to
   * false. There is still a potential race via HTTP but we aren't solving that right now..
   * we may in the future want to queue submissions so that race is solved also but ¯\_(ツ)_/¯
   */
  currentlySubmittingCount: number
}

interface IFieldOptions {
  initialValue?: string
  /**
   * The value the server already has received for this field.
   * IFieldState.submittedValue will reflect this, which will then prevent the field from
   * submitting in a ProvideFormIncremental if IFieldState.submittedValue === IFieldState.value
   *
   * NB: if `initialValue` and `previouslySubmittedValue` are the same (server's value is the initial value),
   * you can set `getPreviouslySubmittedValuesFromInitialValues` (the form's prop) to avoid duplicating
   * `initialValue` and `previouslySubmittedValue` here in `IFieldOptions`.
   */
  previouslySubmittedValue?: string
}

export type TValuePreprocessorFunction<FormFieldsType extends IFieldValues> = (
  fieldName: Extract<keyof FormFieldsType, string>,
  value: string
) => string

/** If the form's `valuePreprocessor` prop is not set, this default will run instead. See that prop for more info. */
export const defaultValuePreprocessor: TValuePreprocessorFunction<any> = (
  _,
  value
) => trim(value)

export const noopValuePreprocessor: TValuePreprocessorFunction<any> = (_, value) =>
  value

export interface IGenerateFormFieldProps<
  FormFieldsType extends IFieldValues,
  SubmissionContextType = undefined
> {
  (
    fieldName: string & keyof FormFieldsType,
    constraints?: TFormConstraint[],
    options?: IFieldOptions
  ): IFormFieldProps<FormFieldsType, SubmissionContextType>
}

export type TProvideFormType = 'normal' | 'incremental'

export type TProvideFormNormalConfig<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> = {
  type: 'normal'
  submit: TProvideFormOnSubmitNormal<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  >
  toFormFields: TProvideFormToFormFieldsNormal<FormFieldsType, SubmissionContextType>
}

// This configuration translates incremental-specific things that
// users of ProvideFormIncremental define into what the ProvideFormBase expects.
export type TProvideFormIncrementalConfigForBaseForm<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> = {
  type: 'incremental'
  submit: TProvideFormOnSubmitIncremental<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  >
  toFormFields: TProvideFormToFormFieldsIncrementalForBaseForm<
    FormFieldsType,
    SubmissionContextType
  >
}

/**
 * Both types of ProvideForm (NORMAL and INCREMENTAL) are configured in their respective subclasses.
 * The configuration that this type describes is passed to ProvideFormBase from the subclasses via the formConfig prop.
 *
 * The configuration sets the TProvideFormType and the `submit` handler function.
 * They are separate because the signature of the `submit` handler is different, based on the TProvideFormType.
 */
export type TProvideFormConfig<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> =
  | TProvideFormNormalConfig<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >
  | TProvideFormIncrementalConfigForBaseForm<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >

export interface IProvideFormBaseProps<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> {
  /** NB: only allowed in ProvideForm normal */
  submitSuccess?: (
    formState: FormFieldsType,
    promiseResult: PromiseResultType
  ) => void
  /** NB: only allowed in ProvideForm normal. Errors can be anything, check if it is a APIError before using */
  submitFailure?: TSubmitFailureHandler<FormFieldsType>
  /**
   * Optionally interrupt the submission flow with some async check, whether it's a confirmation
   * dialog or some other constraint. Return `true` if submission can continue, or `false` if
   * submission should be halted.
   * Useful since formFields often call `onSubmit` directly, but some forms may want to intercept
   * that decision for one reason or another.
   */
  shouldSubmit?: () => boolean
  formConfig: TProvideFormConfig<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  >
  submitText?: string
  hideGlobalErrors?: boolean
  /** Try our best to stop autofill / autocomplete behavior by a browser for this whole form. */
  disableAutoComplete?: boolean
  /**
   * If any fields shouldn't be constrained at the moment, return them in this callback.
   * Fields listed here won't block form submission, nor be reported on `anyErrors`/`haveAnyFieldsChangedFromDefault`
   */
  ignoreConstraintsOnFields?: () => Array<Extract<keyof FormFieldsType, string>>
  analyticsEvent?: string
  immediatelyShowErrorMessages?: boolean
  /** set to true if `options.initialValue` on formFields should be used to populate preSubmittedValues */
  getPreviouslySubmittedValuesFromInitialValues?: boolean
  /**
   * Get updates (outside of render(), unlike using formProps via toFormFields) about the state of the form.
   * This is called any time the form sets its own state (which includes after it mounts)
   */
  formStatus?: (formStatus: IFormStatus<FormFieldsType>) => any
  ignoreSubmitWithoutChanges?: boolean
  /**
   * A list of constraints that depend on something external to the form's individual fields,
   * or anything more complex than a constraint on a single field.
   * The constraint functions will receive some of the form's state via TPeripheralConstraintData.
   */
  peripheralConstraints?: TConstraint<TPeripheralConstraintData<FormFieldsType>>[]

  /**
   * An optional callback to modify the user-input value of a form field at certain milestones:
   * - Before the form runs any constraints
   *   - (so, on every change to a field, and other times constraints are run)
   * - After the user blurs an input
   *   - (but still before the blur action causes incremental fields to submit)
   * - When the form is submitted
   *
   * If undefined, the form will use `defaultValuePreprocessor` to trim (remove leading/trailing whitespace) the input,
   * because the overwhelming majority of our forms want this behavior. If you don't want to trim a specific field,
   * or if you want to do something else to one or more fields before the above things happen, you can define this prop
   * to override this default trimming behavior. Or, use `noopValuePreprocessor` to do nothing.
   *
   * The form field's `IFieldState.value` will not be used to actually overwrite the `IFieldState.value` when it is
   * called just before constraints run. This way the input's value won't change as the user types. However, we still
   * call this function as the user types, so that the preprocessed value will be used when checking constraints.
   * This is a nice way to avoid an error from showing on a field due to e.g. trailing whitespace that will be removed
   * automatically once the field is blurred or the form is submitted.
   *
   * This is different than using an `AdvancedConstraint`'s `setPreProcessor` method - whereas that just modifies the
   * value before the constraint runs, we also want to modify the form's tracked value here sometimes (on blur/submit).
   */
  valuePreprocessor?: TValuePreprocessorFunction<FormFieldsType>

  /* className for the <div> or <form> that will wrap the children of the Form */
  wrapperClassName?: string
}

export type TPeripheralConstraintData<FormFieldsType extends IFieldValues> = {
  fieldStates: TFieldStatesReturnType<FormFieldsType>
  haveAnyFieldsChangedFromDefault: boolean
}

export interface IFormStatus<FormFieldsType extends IFieldValues> {
  unsubmittedChanges: boolean
  anyErrors: boolean
  areAnyFieldsShowingErrors: boolean
  areAnyFieldsSubmitting: boolean
  areAnyFieldsChangedAndNotSubmitting: boolean
  haveAnyFieldsChangedFromDefault: boolean
  fieldValues: FormFieldsType
}

type TFieldStatesReturnType<FormFieldsType extends IFieldValues> = {
  [fieldName in Extract<keyof FormFieldsType, string>]?: IFieldState
}

export interface IFormState<FormFieldsType extends IFieldValues> {
  /**
   * Changing this allows us to manually force the form to re-render.
   * Since formFields are added in our render, where we can't call setState,
   * we need to re-render sometimes. See comments on componentDidMount for more details.
   */
  reRenderTrigger: number
  fieldStates: TFieldStatesReturnType<FormFieldsType>
  serverState: {
    errorMessage: string | null
    /**
     * NB: used to be a boolean but changed to a counter to avoid a race condition where
     * the form was being submitted twice and the first submission would set `isSubmittingWholeForm` to
     * false. There is still a potential race via HTTP but we aren't solving that right now..
     * we may in the future want to queue submissions so that race is solved also but ¯\_(ツ)_/¯
     */
    isSubmittingWholeFormCount: number
  }
}

/**
 * FormFieldsType generic has to be passed to create a ProvideForm. It is enforced in the following ways:
 * a) No field can be added to the form (via generateFormFields) unless its key exists in FormFieldsType
 * b) FormFieldsType is the object submitted to the onSubmit callback and therefore the server
 * c) Internally within ProvideForm the member functions enforce it as much as possible
 *
 * There are three types of errors on this form:
 *  - field:  client-side validations on the contents of an individual field
 *  - global: client-side validations on relationships between fields
 *  - server: errors that are returned from onSubmit method (usually from the server)
 */
export default class ProvideFormBase<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType = undefined
> extends React.Component<
  IProvideFormBaseProps<FormFieldsType, PromiseResultType, SubmissionContextType>,
  IFormState<FormFieldsType>
> {
  initialState: IFormState<FormFieldsType> = {
    reRenderTrigger: 0,
    fieldStates: {},
    serverState: {
      errorMessage: null,
      isSubmittingWholeFormCount: 0,
    },
  }

  constraints: {
    [fieldName in Extract<keyof FormFieldsType, string>]?: AdvancedConstraint<
      string
    >[]
  } = {}
  initialValues: {[fieldName in Extract<keyof FormFieldsType, string>]?: string} = {}
  /**
   * helps populate `changesSubmitted` - fields/values here have been saved to the server already.
   */
  preSubmittedValues: {
    [fieldName in Extract<keyof FormFieldsType, string>]?: string
  } = {}
  // would be nice if we could initialize this set to include all possible keys in FormFieldsType.
  fieldNames: Set<Extract<keyof FormFieldsType, string>> = new Set()

  runValuePreprocessor: TValuePreprocessorFunction<FormFieldsType> =
    this.props.valuePreprocessor ?? defaultValuePreprocessor

  constructor(
    props: IProvideFormBaseProps<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >
  ) {
    super(props)
    this.state = this.initialState
  }

  // override setState so we can call the `this.props.formStatus` callback after each call
  setState<K extends keyof IFormState<FormFieldsType>>(
    state:
      | ((
          prevState: Readonly<IFormState<FormFieldsType>>,
          props: IProvideFormBaseProps<
            FormFieldsType,
            PromiseResultType,
            SubmissionContextType
          >
        ) => Pick<IFormState<FormFieldsType>, K> | IFormState<FormFieldsType> | null)
      | (Pick<IFormState<FormFieldsType>, K> | IFormState<FormFieldsType> | null),
    callback?: () => void
  ): void {
    super.setState(state as any, () => {
      this.sendFormStatus()
      callback && callback()
    })
  }

  buildFormStatus = (): IFormStatus<FormFieldsType> => ({
    anyErrors:
      this.doAnyFieldsHaveServerErrors() || this.determineLocalErrors(false),
    unsubmittedChanges: this.doAnyFieldsHaveUnsubmittedChanges(),
    areAnyFieldsShowingErrors: this.areAnyFieldsShowingErrors(),
    areAnyFieldsSubmitting: this.areAnyFieldsSubmitting(),
    areAnyFieldsChangedAndNotSubmitting: this.areAnyFieldsChangedAndNotSubmitting(),
    haveAnyFieldsChangedFromDefault: this.haveAnyFieldsChangedFromDefault(),
    fieldValues: this.fieldValues() as FormFieldsType,
  })

  sendFormStatus = () => {
    this.props.formStatus?.(this.buildFormStatus())
  }

  clearState = () => {
    this.setState(this.initialState)
  }

  resetField = (fieldName: keyof FormFieldsType) => {
    this.setState(
      (prevState: IFormState<FormFieldsType>): IFormState<FormFieldsType> => {
        return {
          ...prevState,
          fieldStates: {
            ...prevState.fieldStates,
            [fieldName]: undefined,
          },
        }
      }
    )
  }

  // returns a partial for 2 reasons:
  // 1. the code below lazily builds the object, so the required keys aren't present when it's initialized
  // 2. because `fieldNames` and `state.fieldStates` are also partials. We don't know if all FormFieldsType's keys
  //    are fields yet (or ever will be)
  fieldStates: () => TFieldStatesReturnType<FormFieldsType> = () => {
    let fieldStates = this.state.fieldStates
    let retFieldStates: TFieldStatesReturnType<FormFieldsType> = {}

    // because fields are added in ProvideForm's `render`, and we can't set state in render, some fields
    // are saved on `this.fieldNames` instead of the state. so, add those to the return value too.
    // (new fields will be added to the state when `createOrUpdateFieldState` is called for them)
    this.fieldNames.forEach((fieldName: Extract<keyof FormFieldsType, string>) => {
      const fieldState: IFieldState | undefined = fieldStates[fieldName]
      const defaultState: IFieldState = this.getDefaultFieldState(fieldName)

      if (!fieldState) {
        retFieldStates[fieldName] = defaultState
      } else {
        // in case of a change to `initialValues`, the form will re-render, and here we have the chance
        // to re-set `isDefaultValue` to true, if that's the case now/still.
        // either:
        // - this field's value hasn't been changed by the user yet.
        //   we should update its value to the new initialValue in case it changed.
        // OR
        // - the user has changed the field's value to align with the initialValue again
        //   we can set `isDefaultValue` back to true.
        //   NB: this means a future change to initialValues will change this field automatically again
        //   as if the user hadn't played with the value at all yet
        if (fieldState.isDefaultValue || fieldState.value === defaultState.value) {
          // if the field isDefaultValue, we want to keep the field's value up to date with initialValues, which
          // can change over time.
          const shouldUpdateFieldValueToNewDefault =
            fieldState.isDefaultValue && fieldState.value !== defaultState.value

          fieldState.localErrorMessage = defaultState.localErrorMessage
          if (shouldUpdateFieldValueToNewDefault) {
            fieldState.value = defaultState.value

            // don't reset the server error message unless the initialValue actually changed
            // just because it's a default value doesn't mean the server didn't reject it previously.
            fieldState.serverErrorMessage = null
          }
          fieldState.isDefaultValue = true
        }
        retFieldStates[fieldName] = fieldState
      }
    })

    return retFieldStates
  }

  fieldValues: () => {
    [fieldName in Extract<keyof FormFieldsType, string>]?: string
  } = () => {
    return R.mapObjIndexed(
      (field: IFieldState) => field.value,
      this.fieldStates()
    ) as {[fieldName in Extract<keyof FormFieldsType, string>]?: string}
  }

  setValue = (fieldName: Extract<keyof FormFieldsType, string>) => (
    value: string,
    callback?: () => void
  ) => {
    this.createOrUpdateFieldState(fieldName, () => ({value}), callback)
  }

  ignoreConstraintsOnField = (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    if (!this.props.ignoreConstraintsOnFields) {
      return false
    } else {
      return this.props.ignoreConstraintsOnFields().indexOf(fieldName) > -1
    }
  }

  setShouldShowErrorMessages = () => {
    R.keys(this.fieldStates()).map(
      (fieldName: Extract<keyof FormFieldsType, string>) => {
        if (!this.ignoreConstraintsOnField(fieldName)) {
          this.setShouldShowErrorMessageOnField(fieldName)
        }
      }
    )
  }

  setShouldShowErrorMessageOnField = (
    fieldName: Extract<keyof FormFieldsType, string>,
    callback?: () => void
  ) => {
    this.createOrUpdateFieldState(
      fieldName,
      () => ({shouldShowErrorMessage: true}),
      callback
    )
  }

  setLocalErrorMessage = (
    fieldName: Extract<keyof FormFieldsType, string>,
    localErrorMessage: string | null,
    callback?: () => void
  ) => {
    this.createOrUpdateFieldState(fieldName, () => ({localErrorMessage}), callback)
  }

  setServerErrorMessage = (
    fieldName: Extract<keyof FormFieldsType, string>,
    serverErrorMessage: string | null,
    callback?: () => void
  ) => {
    this.createOrUpdateFieldState(fieldName, () => ({serverErrorMessage}), callback)
  }

  setFieldIsSubmitting = (
    fieldName: Extract<keyof FormFieldsType, string>,
    isSubmitting: boolean,
    callback?: () => void
  ) => {
    const update = isSubmitting ? 1 : -1
    this.createOrUpdateFieldState(
      fieldName,
      prevFieldState => {
        return {
          currentlySubmittingCount: Math.max(
            prevFieldState.currentlySubmittingCount + update,
            0
          ),
        }
      },
      callback
    )
  }

  /**
   * Sets the state of the form with an update for one field's IFieldState
   * @param {(keyof FormFieldsType)} fieldName The fieldName of the form field to update the
   *        state about.
   * @param {Partial<IFieldState>} updateCallback The change of the field's state to apply.
   * @param {() => void} callback A callback to call when the setState finishes.
   */
  createOrUpdateFieldState = (
    fieldName: Extract<keyof FormFieldsType, string>,
    updateCallback: (prevFieldState: IFieldState) => Partial<IFieldState>,
    callback?: () => void
  ) => {
    this.setState((prevState: IFormState<FormFieldsType>): IFormState<
      FormFieldsType
    > => {
      const prevFieldStates = prevState.fieldStates
      const defaultFieldState = this.getDefaultFieldState(fieldName)
      let prevFieldState: IFieldState

      if (prevFieldStates[fieldName]) {
        prevFieldState = prevFieldStates[fieldName]!
      } else {
        prevFieldState = defaultFieldState
      }
      const update = updateCallback(prevFieldState)

      if (update.localErrorMessage !== undefined && update.value !== undefined) {
        // just avoiding the need to decide whether the code below should
        // override an update.errorMessage passed in or use it instead of recomputing
        // if both an errorMessage and value are passed in at once.
        // if we need to do that at some point, we can decide then what to do
        throw Error(
          'ProvideForm asked to update both a value and localErrorMessage on a form field: ' +
            JSON.stringify(update)
        )
      }

      if (update.localErrorMessage === undefined && update.value !== undefined) {
        // this update request isn't trying to set the localErrorMessage state,
        // but it is changing the value, so we should also set
        // the localErrorMessage (or clear it) based on the new value
        const failedConstraint = this.getFailedConstraintResponseOnField(
          fieldName,
          update.value
        )
        update.localErrorMessage = failedConstraint?.message ?? null
        // all changes should clear the server error
        update.serverErrorMessage = null
      }

      const mergedFieldState = R.mergeDeepRight(prevFieldState, update)
      mergedFieldState.isDefaultValue =
        mergedFieldState.value === defaultFieldState.value

      return {
        ...prevState,
        fieldStates: {
          ...prevFieldStates,
          [fieldName]: mergedFieldState,
        },
      }
    }, callback)
  }

  /**
   * Check for an error from a non-global constraint on a form field
   * @param {(keyof IFormState)} key
   * @param {string} value
   * @returns {TAdvancedConstraintFailureResponse | null}  The constraint failure response, if any.
   */
  getFailedConstraintResponseOnField(
    key: Extract<keyof FormFieldsType, string>,
    value: string
  ): TAdvancedConstraintFailureResponse | null {
    let failure: TAdvancedConstraintFailureResponse | null = null

    const preprocessedValue = this.runValuePreprocessor(key, value)

    const constraints = this.constraints[key]
    if (!!constraints) {
      constraints.some(c => {
        const testResult = c.test(preprocessedValue)
        if (testResult.type === 'fail') {
          failure = testResult
        }
        return !!failure
      })
    }
    return failure
  }

  determineLocalErrorMessageForField(
    key: Extract<keyof FormFieldsType, string>,
    value: string
  ): string | null {
    const failedConstraint = this.getFailedConstraintResponseOnField(key, value)
    return !!failedConstraint ? failedConstraint.message : null
  }

  /**
   * Update the errorMessage on a form field from a non-global constraint
   * @param {(keyof FormFieldsType)} key the fieldName of the field to update
   * @param {string} value the value contained by the field to check errors on
   * @returns {boolean} Whether or not there was an error for the field
   */
  updateLocalErrorMessageOnField = (
    key: Extract<keyof FormFieldsType, string>,
    value: string
  ): boolean => {
    const errorMessage = this.determineLocalErrorMessageForField(key, value)
    // (clears error if errorMessage is null)
    this.setLocalErrorMessage(key, errorMessage)
    return errorMessage !== null
  }

  // just avoiding some annoying type system whining here w/ forEachObjIndexed for now
  forEachFieldState = (
    callback: (
      fieldState: IFieldState,
      fieldName: Extract<keyof FormFieldsType, string>
    ) => void
  ) => {
    const currentFieldStates = this.fieldStates()

    return R.forEachObjIndexed(
      (fs, fn) => !!fs && callback(fs!, fn),
      currentFieldStates
    )
  }

  /**
   * Checks for current errors on the form, optionally sets the state of the
   * form based on the results of each constraint check.
   * @param {boolean} mutateState Whether or not to set the state of the form
   * while checking for current errors.
   * @returns {boolean} Whether or not there are any errors on the form.
   */
  determineLocalErrors = (mutateState: boolean): boolean => {
    let errored = false

    this.forEachFieldState((fieldState, fieldName) => {
      errored =
        this.determineLocalErrorsOnField(fieldName, fieldState, mutateState) ||
        errored
    })

    return errored || this.determinePeripheralConstraintFailures()
  }

  determineLocalErrorsOnField = (
    fieldName: Extract<keyof FormFieldsType, string>,
    fieldState: IFieldState,
    mutateState: boolean
  ): boolean => {
    if (this.ignoreConstraintsOnField(fieldName)) {
      // ignore the field's error for now.
      return false
    }

    if (mutateState) {
      return this.updateLocalErrorMessageOnField(fieldName, fieldState.value)
    } else {
      return !!this.getFailedConstraintResponseOnField(fieldName, fieldState.value)
    }
  }

  determinePeripheralConstraintFailures = (): boolean => {
    const constraintData: TPeripheralConstraintData<FormFieldsType> = {
      fieldStates: this.state.fieldStates,
      haveAnyFieldsChangedFromDefault: this.haveAnyFieldsChangedFromDefault(),
    }

    return R.any(c => {
      const advanced = c instanceof AdvancedConstraint ? c : basicToAdvanced(c)
      return advanced.test(constraintData).type === 'fail'
    }, this.props.peripheralConstraints ?? [])
  }

  peripheralConstraintErrorMessage = (): string | null => {
    const constraintData: TPeripheralConstraintData<FormFieldsType> = {
      fieldStates: this.state.fieldStates,
      haveAnyFieldsChangedFromDefault: this.haveAnyFieldsChangedFromDefault(),
    }

    const constraints: TConstraint<TPeripheralConstraintData<FormFieldsType>>[] =
      this.props.peripheralConstraints ?? []

    let message: string | null = null
    constraints.find(c => {
      const advanced = c instanceof AdvancedConstraint ? c : basicToAdvanced(c)
      const testResult = advanced.test(constraintData)
      message = testResult.type === 'fail' ? testResult.message : message
      return message !== null
    })
    return message
  }

  onChanges = (
    newFieldValues: {[fieldName in Extract<keyof FormFieldsType, string>]?: string},
    callback?: () => void
  ) => {
    const fixedNewValues = purgeUndefined(newFieldValues)
    const keys = R.keys(fixedNewValues)

    if (keys.length === 0) {
      callback?.()
      return
    }

    for (let i = 0; i < keys.length; i++) {
      const fieldName = keys[i]
      // `purgeUndefined` above justifies this cast
      const newValue = fixedNewValues[fieldName]!

      this.setValue(fieldName)(newValue, () => {
        // multiple fields being set at once, we should treat them as blurred
        // NB: doing this in the `setValue` callback so that the blur method reads the new field value
        //     to decide if there are any new changes to submit or not
        this.onBlurField(fieldName)

        if (i === keys.length - 1) {
          callback?.()
        }
      })
    }
  }

  submitIncremental = (
    formConfig: TProvideFormIncrementalConfigForBaseForm<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >
  ) => (fieldName: Extract<keyof FormFieldsType, string>) => (
    context?: SubmissionContextType
  ): void => {
    // before running the submit code, we want to run the value preprocessor. Normally it runs every time
    // a field is blurred, but some fields won't be blurred before the form is submitted (e.g. a field that allows
    // submission via the Enter key, or by using `<AutoSubmittingFormField>` that submits fields on a timer)
    this.setValue(fieldName)(
      this.runValuePreprocessor(fieldName, this.fieldStates()[fieldName]!.value),
      () => {
        const fieldStates = this.fieldStates()
        const fieldState = fieldStates[fieldName]! // cant be undefined now

        // show all applicable errors once they try to submit
        this.setShouldShowErrorMessageOnField(fieldName)

        // NB: mutateState is false in the `determineLocalErrorsOnField` here below, but that doesn't really matter.
        // (the localErrorMessage is set in `createOrUpdateFieldState` when the field value changes, and
        // incremental form fields - unlike normal form fields - are only ever submitted after they are
        // changed & blurred, so the localErrorMessage was already set there)
        let didError = this.determineLocalErrorsOnField(fieldName, fieldState, false)
        if (didError || this.determinePeripheralConstraintFailures()) {
          return
        }

        if (
          this.props.ignoreSubmitWithoutChanges &&
          !this.doesFieldHaveUnsubmittedChanges(fieldStates)(fieldName)
        ) {
          // don't bother
          return
        }

        if (this.props.shouldSubmit && !this.props.shouldSubmit()) {
          return
        }

        // NB: incremental forms don't set the serverState. see catch below for more comments.
        this.setFieldIsSubmitting(fieldName, true)
        const fieldValue = fieldState.value

        formConfig
          .submit(fieldName, fieldValue, context)
          .then((result: any) => {
            this.createOrUpdateFieldState(fieldName, prevFieldState => ({
              submittedValue: fieldValue,
              currentlySubmittingCount: Math.max(
                prevFieldState.currentlySubmittingCount - 1,
                0
              ),
            }))
          })
          .catch((e: any) => {
            console.warn(
              'submitIncremental: Server errors from form submission: ',
              e
            )

            // push the error to the field since that the only thing being updated
            // this could potentially be weird if the server is erroring on something unrelated
            // but the user should see it straight away on the field.
            if (e instanceof APIFormError) {
              this.setServerErrorMessage(fieldName, e.firstErrorMessage())
            } else {
              if (typeof e === 'string') {
                this.setServerErrorMessage(fieldName, e)
              } else {
                let errorMessage = 'A server error occurred.'
                if (e instanceof APIInvalidArgumentsError) {
                  errorMessage = 'JSON parsing error.'
                }
                this.setServerErrorMessage(fieldName, errorMessage)
              }

              /* eslint-disable max-len */
              const bugsnagErrorMessage = `ProvideForm incremental submission had an error in an unexpected format for field: '${fieldName}'`
              console.error(bugsnagErrorMessage, e)
            }

            // NOTE: in the incremental case we are not going to ever add a
            // serverState.errorMessage so below the serverState.errorMessage is left null
            // this is because a) it would be hard to show that to the user as they are
            // only updating the one field. Instead we just push any global errors to the fields
            // error. b) it would be a pain to clear form level errors on incremental submits
            this.setFieldIsSubmitting(fieldName, false)
          })
      }
    )
  }

  submitNormal = (
    formConfig: TProvideFormNormalConfig<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >
  ) => (submissionContext?: SubmissionContextType): void => {
    // a function that runs the submission code that we'll call later, after running the value preprocessor
    const actuallyRunSubmit = () => {
      // show all applicable errors once they try to submit
      this.fieldNames.forEach(fieldName =>
        this.setShouldShowErrorMessageOnField(fieldName)
      )

      // NB: this checks all non-ignored fields for errors and also checks peripheralConstraints
      if (this.determineLocalErrors(true)) {
        return
      }

      if (
        this.props.ignoreSubmitWithoutChanges &&
        !this.doAnyFieldsHaveUnsubmittedChanges()
      ) {
        // don't bother
        return
      }

      if (this.props.shouldSubmit && !this.props.shouldSubmit()) {
        return
      }

      this.setState(
        (prevState: IFormState<FormFieldsType>): IFormState<FormFieldsType> => ({
          ...prevState,
          serverState: {
            ...prevState.serverState,
            errorMessage: null,
            isSubmittingWholeFormCount:
              prevState.serverState.isSubmittingWholeFormCount + 1,
          },
        })
      )

      // this.fieldValues() is a Partial<FormFieldsType> because we don't actually know if all the fields
      // from FormFieldsType were ever given via generateFormFieldProps(). The cast gets rid of that.
      // it's the caller's job to know & manage which fields have been added, for now.
      const fieldValues = this.fieldValues() as FormFieldsType

      formConfig
        .submit(fieldValues, {submissionContext, formStatus: this.buildFormStatus()})
        .then((result: PromiseResultType) => {
          this.setState(
            (prevState: IFormState<FormFieldsType>): IFormState<FormFieldsType> => {
              const fieldStatesWithSubmittedValue = R.mapObjIndexed(
                (fieldState: IFieldState, fieldName: keyof FormFieldsType) => {
                  if (fieldValues[fieldName] !== undefined) {
                    return {
                      ...fieldState,
                      submittedValue: fieldValues[fieldName],
                    }
                  }
                  return fieldState
                },
                prevState.fieldStates
              ) as Partial<{[fieldName in keyof FormFieldsType]: IFieldState}>

              return {
                ...prevState,
                fieldStates: fieldStatesWithSubmittedValue,
                serverState: {
                  ...prevState.serverState,
                  errorMessage: null,
                  isSubmittingWholeFormCount:
                    prevState.serverState.isSubmittingWholeFormCount - 1,
                },
              }
            },
            () => {
              this.props.submitSuccess?.(fieldValues, result)
            }
          )
        })
        .catch((e: unknown) => {
          console.warn('submitNormal: Server errors from form submission: ', e)

          let globalError = 'A server error occurred.'

          if (e instanceof APIFormError) {
            const errors = e.formErrors
            const errorKeys = e.errorKeys()
            globalError = e.soleErrorMessageOr(
              'Please fix all errors before continuing.'
            )

            // Cast to any because the type is Set<T>.prototype.has(T val) but irl will accept any type
            const fieldErrorKeys = errorKeys.filter(key =>
              this.fieldNames.has(key as any)
            )
            const globalErrorKeys = errorKeys.filter(
              key => !this.fieldNames.has(key as any)
            )

            const fieldErrorIndicator =
              fieldErrorKeys.length > 0
                ? ['Please fix all errors before continuing.']
                : []

            globalError = [
              ...fieldErrorIndicator,
              globalErrorKeys.map(key => errors[key][0]).join(' — '),
            ].join(' ')

            fieldErrorKeys.map(key =>
              this.setServerErrorMessage(
                key as Extract<keyof FormFieldsType, string>,
                errors[key][0]
              )
            )
          } else if (e instanceof APIInvalidArgumentsError) {
            globalError = 'JSON parsing error.'
            console.error(
              'ProvideForm normal submission had a JSON-parsing exception on the backend.',
              e
            )
          } else {
            if (typeof e === 'string') {
              globalError = e
            }

            if (!(e instanceof APIError)) {
              console.error(
                'ProvideForm normal submission had an error in an unexpected format.',
                e
              )
            }
          }

          this.setState(
            (prevState: IFormState<FormFieldsType>): IFormState<FormFieldsType> => ({
              ...prevState,
              serverState: {
                ...prevState.serverState,
                errorMessage: globalError,
                isSubmittingWholeFormCount:
                  prevState.serverState.isSubmittingWholeFormCount - 1,
              },
            }),
            () => {
              this.props.submitFailure?.(fieldValues, e)
            }
          )
        })
    }

    // before running the submit code above, we want to run the value preprocessor. Normally it runs every time
    // a field is blurred, but some fields won't be blurred before the form is submitted (e.g. a field that allows
    // submission via the Enter key, or by using `<AutoSubmittingFormField>` that submits fields on a timer)
    const originalFieldStates = purgeUndefined(this.fieldStates())
    const fieldNames = R.keys(originalFieldStates)

    if (fieldNames.length === 0) {
      actuallyRunSubmit()
      return
    }

    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i] as Extract<keyof FormFieldsType, string>
      // cast justified via purgeUndefined above (even though it is probably unnecessary)
      const originalFieldState = originalFieldStates[fieldName]!

      this.setValue(fieldName)(
        this.runValuePreprocessor(fieldName, originalFieldState.value),
        () => {
          if (i === fieldNames.length - 1) {
            actuallyRunSubmit()
          }
        }
      )
    }
  }

  // This allows us to get the state of the field *without* triggering any side-effects
  // Don't add calls to e.g. getDefaultFieldState or fieldStates here, we use it inside constraints
  getFieldValue(
    fieldName: Extract<keyof FormFieldsType, string>
  ): string | undefined {
    const fieldState = this.state.fieldStates[fieldName]
    return fieldState !== undefined
      ? fieldState.value
      : this.initialValues[fieldName]
  }

  /** Field can either have a value because the user has typed something in them
   * or they have the value given to them by this function.
   * Before a user edits or before a submission the field does not have a value in
   * the form state and instead its value comes from this function.
   * The reason to do it like this is to avoid having to setState until later and
   * therefore let us pass in the fieldName in the render object below (since state can not be
   * set in a render)
   */
  getDefaultFieldState(
    fieldName: Extract<keyof FormFieldsType, string>
  ): IFieldState {
    const initialValue: string | undefined = this.initialValues[fieldName]
    const submittedValue: string | undefined = this.preSubmittedValues[fieldName]
    const value = initialValue || ''

    return {
      value,
      localErrorMessage: this.determineLocalErrorMessageForField(fieldName, value),
      serverErrorMessage: null,
      shouldShowErrorMessage: !!this.props.immediatelyShowErrorMessages,
      isDefaultValue: true,
      submittedValue: submittedValue || '',
      currentlySubmittingCount: 0,
    }
  }

  /** This method lets us add in fieldName, constraints, and options from the render
   * of the ProvideForm.
   * We basically save the constraints in this.constraints
   * and the fieldNames in this.fieldNames. We then later derive errors
   * from them and also save them to the state when necessary
   * Since this is called at render you can't set state here.
   */
  addFieldToForm(
    fieldName: Extract<keyof FormFieldsType, string>,
    constraints?: TFormConstraint[],
    options?: IFieldOptions
  ): IFieldState {
    if (!!constraints) {
      this.constraints[fieldName] = constraints.map(c =>
        c instanceof AdvancedConstraint ? c : basicToAdvanced(c)
      )
    }

    if (!!options) {
      if (options.initialValue !== undefined) {
        this.initialValues[fieldName] = options.initialValue
        if (this.props.getPreviouslySubmittedValuesFromInitialValues) {
          this.preSubmittedValues[fieldName] = options.initialValue
        }
      }

      // previouslySubmittedValue overwrites initialValue even if getPreviouslySubmittedValuesFromInitialValues is true
      if (!!options.previouslySubmittedValue) {
        this.preSubmittedValues[fieldName] = options.previouslySubmittedValue
      }
    }

    this.fieldNames.add(fieldName)

    // since `fieldName` was just added to `this.fieldNames` it's guaranteed to be in `this.fieldStates()`
    return this.fieldStates()[fieldName]!
  }

  isFormSubmitting() {
    return this.state.serverState.isSubmittingWholeFormCount > 0
  }

  isFieldEmpty = (fieldName: Extract<keyof FormFieldsType, string>): boolean => {
    const fieldValue = this.getFieldValue(fieldName)
    return !fieldValue || R.isEmpty(fieldValue)
  }

  hasFieldChangedFromDefault = (fs: TFieldStatesReturnType<FormFieldsType>) => (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    const fieldState = fs[fieldName]
    return fieldState && !fieldState.isDefaultValue
  }

  haveAnyFieldsChangedFromDefault = (): boolean => {
    const fs = this.fieldStates()
    return R.any(
      this.hasFieldChangedFromDefault(fs),
      Array.from(this.fieldNames).filter(
        name => !this.ignoreConstraintsOnField(name)
      )
    )
  }

  doesFieldHaveUnsubmittedChanges = (fs: TFieldStatesReturnType<FormFieldsType>) => (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    const fieldState = fs[fieldName]
    return fieldState && fieldState.value !== fieldState.submittedValue
  }

  doAnyFieldsHaveUnsubmittedChanges = (): boolean => {
    return S.nonempty(this.fieldsWithUnsubmittedChanges())
  }

  fieldsWithUnsubmittedChanges = (): Set<Extract<keyof FormFieldsType, string>> => {
    const fs = this.fieldStates()
    return S.filter(
      name =>
        !this.ignoreConstraintsOnField(name) &&
        this.doesFieldHaveUnsubmittedChanges(fs)(name),
      this.fieldNames
    )
  }

  isFieldShowingAnError = (fs: TFieldStatesReturnType<FormFieldsType>) => (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    const fieldState = fs[fieldName]
    return (
      fieldState &&
      fieldState.shouldShowErrorMessage &&
      (fieldState.localErrorMessage !== null ||
        fieldState.serverErrorMessage !== null)
    )
  }

  areAnyFieldsShowingErrors = (): boolean => {
    const fs = this.fieldStates()
    return R.any(this.isFieldShowingAnError(fs), Array.from(this.fieldNames))
  }

  doesFieldHaveAServerError = (fs: TFieldStatesReturnType<FormFieldsType>) => (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    const fieldState = fs[fieldName]
    return fieldState && fieldState.serverErrorMessage !== null
  }

  doAnyFieldsHaveServerErrors = (): boolean => {
    const fs = this.fieldStates()
    return R.any(this.doesFieldHaveAServerError(fs), Array.from(this.fieldNames))
  }

  isFieldSubmitting = (fs: TFieldStatesReturnType<FormFieldsType>) => (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    const fieldState = fs[fieldName]
    return fieldState && fieldState.currentlySubmittingCount > 0
  }

  areAnyFieldsSubmitting = (): boolean => {
    const fs = this.fieldStates()
    return R.any(this.isFieldSubmitting(fs), Array.from(this.fieldNames))
  }

  isFieldChangedAndNotSubmitting = (fs: TFieldStatesReturnType<FormFieldsType>) => (
    fieldName: Extract<keyof FormFieldsType, string>
  ): boolean => {
    return (
      !this.isFieldSubmitting(fs)(fieldName) &&
      this.doesFieldHaveUnsubmittedChanges(fs)(fieldName)
    )
  }

  areAnyFieldsChangedAndNotSubmitting = (): boolean => {
    const fs = this.fieldStates()
    return R.any(
      this.isFieldChangedAndNotSubmitting(fs),
      Array.from(this.fieldNames)
    )
  }

  // FormFields are added in the render of the ProvideForm. Because of that, `formProps.anyErrors`
  // won't check any constraints on the first render of the form (there are no form fields yet).
  // We need the form to re-render itself a second time, so the form fields are picked up and the
  // constraints are checked. this does that (`componentDidMount` is called after `render()` completes,
  // and from testing it is also after the children we're providing a form to are rendered)
  componentDidMount() {
    this.changeReRenderTrigger()
  }
  // Similarly, if a form field is added later, (e.g. due to user interaction), call this so the form
  // can recompute the constraints.
  formFieldAdded = () => {
    this.changeReRenderTrigger()
  }
  changeReRenderTrigger() {
    this.setState((prevState: IFormState<FormFieldsType>) => {
      return {reRenderTrigger: (prevState.reRenderTrigger + 1) % 1000}
    })
  }

  /*
  UPDATE dec 2018:
  ----------------
  since chrome 71 (?), `<form>`'s absence alone doesn't stop Chrome from autofilling fields.
  leaving it here since other browsers may still be tricked by this for now.
  instead, inputs can use a random string for the autoComplete attr. to try and avoid it
  (search for DISABLE_AUTOCOMPLETE_STRING for more)

  Original comment:
  -----------------
  an actual <form> is needed for autocomplete in Chrome, and we need a preventDefault
  in <form>s so pressing 'enter' doesn't cause an error message in console
  */
  FormWrapper = (props: {children: React.ReactNode}) => {
    return this.props.disableAutoComplete ? (
      <div className={this.props.wrapperClassName}>{props.children}</div>
    ) : (
      <form
        className={this.props.wrapperClassName}
        onKeyPress={(event: React.KeyboardEvent<HTMLFormElement>) => {
          // @ts-ignore: `type` exists according to the JS console.
          if (event.key === 'Enter' && event.target.type !== 'textarea') {
            /* we handle form submission ourselves, including the enter key causing
               submission from text fields, so prevent HTML <form>s trying to handle it for us.
               <textarea>s want their enter button for newlines, though, and preventDefault()
               will prevent those, too, so check for that first.
               */
            event.preventDefault()
          }
        }}
      >
        {props.children}
      </form>
    )
  }

  // these user-defined type guards tell the type system we know the type of formConfig so we don't
  // have to do `if (type === NORMAL) {...} else {throw new Error()}` everywhere we use it
  // NB: this is the same reason we then pass the type-checked onSubmit to submitNormal/submitIncremental
  isNormalForm = (
    formConfig: TProvideFormConfig<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >
  ): formConfig is TProvideFormNormalConfig<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  > => {
    return formConfig.type === 'normal'
  }
  isIncrementalForm = (
    formConfig: TProvideFormConfig<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    >
  ): formConfig is TProvideFormIncrementalConfigForBaseForm<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  > => {
    return formConfig.type === 'incremental'
  }

  onBlurField = (fieldName: Extract<keyof FormFieldsType, string>) => {
    const fieldStates = this.fieldStates()

    // avoid a crash below if `onChanges` calls `onBlurField` for a field that doesn't exist in our
    // this.fieldStates (yet / ever)
    // I had this happen when a field was always hidden on a page but I used formProps.onChanges to try and clear
    // the value of the form field
    const originalFieldState: IFieldState | undefined = fieldStates[fieldName]
    if (!originalFieldState) {
      console.error(
        'ProvideForm was asked to blur a field it does not know about yet. Field:',
        fieldName
      )
      return
    }

    this.setValue(fieldName)(
      this.runValuePreprocessor(fieldName, originalFieldState.value),
      () => {
        const updatedFieldStates = this.fieldStates()

        // don't show an error message unless:
        // - they removed an initial value
        // - they left the field with a value in it (don't error if they just tab past it)
        if (
          this.initialValues[fieldName]?.length ||
          updatedFieldStates[fieldName]!.value.length > 0
        ) {
          this.setShouldShowErrorMessageOnField(fieldName)
        }
        if (
          this.isIncrementalForm(this.props.formConfig) &&
          this.doesFieldHaveUnsubmittedChanges(updatedFieldStates)(fieldName)
        ) {
          // for ProvideFormIncremental, we want to submit on blur
          this.submitIncremental(this.props.formConfig)(fieldName)(undefined)
        }
      }
    )
  }

  render() {
    const formConfig = this.props.formConfig
    const fieldStates = this.fieldStates()

    const generateFormFieldProps: IGenerateFormFieldProps<
      FormFieldsType,
      SubmissionContextType
    > = (
      fieldName: any,
      constraints?: TFormConstraint[],
      options?: IFieldOptions
    ) => {
      const currentState: IFieldState = this.addFieldToForm(
        fieldName,
        constraints,
        options
      )
      return {
        fieldName,
        value: currentState.value,
        errorMessage: currentState.shouldShowErrorMessage
          ? currentState.localErrorMessage || currentState.serverErrorMessage
          : null,
        onBlur: () => {
          this.onBlurField(fieldName)
        },
        onChange: (value: string, callback?: () => void) =>
          this.setValue(fieldName)(value, callback),
        // for ProvideFormIncremental, `onSubmit` will only submit this field
        onSubmit: this.isNormalForm(formConfig)
          ? this.submitNormal(formConfig)
          : this.submitIncremental(formConfig)(fieldName),
        failingConstraint: this.getFailedConstraintResponseOnField(
          fieldName,
          currentState.value
        ),
        clearState: this.clearState,
        hasFieldChanged: this.hasFieldChangedFromDefault(fieldStates)(fieldName),
        resetField: () => this.resetField(fieldName),
        unsubmittedChange: this.doesFieldHaveUnsubmittedChanges(fieldStates)(
          fieldName
        ),
        isFieldSubmitting: this.isNormalForm(formConfig)
          ? this.isFormSubmitting()
          : this.isFieldSubmitting(fieldStates)(fieldName),
        formDisabledAutoComplete: !!this.props.disableAutoComplete,
      }
    }

    const formPropsBase: IFormPropsBase<FormFieldsType> = {
      anyErrors:
        this.doAnyFieldsHaveServerErrors() || this.determineLocalErrors(false),
      onChanges: this.onChanges,
      clearState: this.clearState,
      formFieldAdded: this.formFieldAdded,
      runAllConstraints: (showErrors: boolean = false) => {
        if (showErrors) {
          this.setShouldShowErrorMessages()
        }
        this.determineLocalErrors(true)
      },
      globalErrorMessage:
        this.peripheralConstraintErrorMessage() ||
        this.state.serverState.errorMessage,
      hasFieldChanged: this.hasFieldChangedFromDefault(fieldStates),
      isFieldEmpty: this.isFieldEmpty,
      haveAnyFieldsChangedFromDefault: this.haveAnyFieldsChangedFromDefault,
      unsubmittedChanges: this.doAnyFieldsHaveUnsubmittedChanges,
      fieldsWithUnsubmittedChanges: this.fieldsWithUnsubmittedChanges,
    }

    const renderFormFields = () => {
      if (this.isNormalForm(formConfig)) {
        // NB: strongly declaring the type here so static type errors will be caught
        const normalFormProps: IFormProps<FormFieldsType, SubmissionContextType> = {
          ...formPropsBase,
          onSubmit: this.submitNormal(formConfig),
          isFormSubmitting: this.isFormSubmitting(),
        }

        return formConfig.toFormFields(generateFormFieldProps, normalFormProps)
      } else {
        // NB: strongly declaring the type here so static type errors will be caught
        const incrementalFormProps: IFormPropsIncrementalFromBaseForm<
          FormFieldsType,
          SubmissionContextType
        > = {
          ...formPropsBase,
          areAnyFieldsSubmitting: this.areAnyFieldsSubmitting(),
        }
        return formConfig.toFormFields(generateFormFieldProps, incrementalFormProps)
      }
    }

    return (
      <this.FormWrapper>
        {!this.props.hideGlobalErrors &&
          isPresent(formPropsBase.globalErrorMessage) && (
            <GlobalErrorDisplay>
              {formPropsBase.globalErrorMessage}
            </GlobalErrorDisplay>
          )}
        {renderFormFields()}
      </this.FormWrapper>
    )
  }
}

export class GlobalErrorDisplay extends React.Component<{}, {}> {
  render() {
    return <div className={styles['global-error']}>{this.props.children}</div>
  }
}
