import classnames from 'classnames'
import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import LoadingDots from '~/components/LoadingDots'
import LoadingSpinner from '~/components/LoadingSpinner'
import DSLink, {
  DSExternalLink,
  IDSLinkProps,
  IExternalLinkProps,
} from '~/design-system/Link'
import styles from './styles.module.css'

export type ButtonStyle =
  | 'none'
  | 'primary'
  | 'primary-dark'
  | 'secondary' // secondary: background color is pearl-grey
  | 'secondary-black' // secondary but with black text
  | 'secondary-with-icon'
  | 'tertiary' // tertiary: background color is off-white
  | 'tertiary-with-icon'
  | 'tertiary-white-with-icon'
  | 'tertiary-destructive'
  // one-off buttons not in the DS
  | 'attachedToInput' // custom button used in set pin

export type ButtonSize = 'large' | 'medium' | 'small' | 'tiny' | 'none'

// dotDotDot is default
type TLoadingStyle = 'dotDotDot' | 'spinner' | 'none'

export interface IDSButtonProps
  extends IDSButtonBaseProps,
    OmitStrict<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'onKeyDown'
    > {
  buttonRef?: React.RefObject<HTMLButtonElement>
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => void
}

class DSButton extends React.Component<IDSButtonProps> {
  render() {
    const {
      // DSButton-specific props
      buttonRef,
      onClick,

      // DSButtonBase props
      appearDisabled,
      disabled,
      loading,
      loadingStyle,
      stretch,
      buttonStyle,
      size,
      shadow,

      // HTML `<button>` props
      children,
      ...htmlButtonProps
    } = this.props

    const handleClick = (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLButtonElement>
    ) => {
      onClick?.(event)
    }

    return (
      <DSButtonBase
        appearDisabled={appearDisabled}
        disabled={disabled}
        loading={loading}
        stretch={stretch}
        buttonStyle={buttonStyle}
        size={size}
        shadow={shadow}
        loadingStyle={loadingStyle}
        children={baseProps => (
          <button
            ref={buttonRef}
            {...htmlButtonProps}
            disabled={disabled}
            className={classnames(
              baseProps.buttonClassNames,
              htmlButtonProps.className
            )}
            onClick={e => {
              // NB: this is also called from the keyboard when you use the space bar
              e.preventDefault()
              handleClick(e)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleClick(e)
              }
            }}
          >
            {baseProps.buttonContents(children)}
          </button>
        )}
      />
    )
  }
}

export default DSButton

export interface IDSButtonLinkProps
  extends IDSButtonBaseProps,
    // use `href` instead
    OmitStrict<IDSLinkProps, 'onClick'> {}

/* Styled exactly like DSButton, but is a `<DSLink>` instead of a `<button>` */
export class DSButtonLink extends React.Component<IDSButtonLinkProps> {
  render() {
    const {
      // DSButtonBase props
      appearDisabled,
      disabled,
      loading,
      loadingStyle,
      stretch,
      buttonStyle,
      size,
      shadow,

      // DSLink props
      children,
      className,
      ...dsLinkProps
    } = this.props

    return (
      <DSButtonBase
        appearDisabled={appearDisabled}
        disabled={disabled}
        loading={loading}
        stretch={stretch}
        buttonStyle={buttonStyle}
        size={size}
        shadow={shadow}
        loadingStyle={loadingStyle}
        children={baseProps => (
          <DSLink
            {...dsLinkProps}
            className={classnames(baseProps.buttonClassNames, className)}
          >
            {baseProps.buttonContents(children)}
          </DSLink>
        )}
      />
    )
  }
}

export interface IDSButtonExternalLinkProps
  extends IDSButtonBaseProps,
    IExternalLinkProps {}

/* Styled exactly like DSButton, but is a `<DSExternalLink>` instead of a `<button>` */
export class DSButtonExternalLink extends React.Component<IDSButtonExternalLinkProps> {
  render() {
    const {
      // DSButtonBase props
      appearDisabled,
      disabled,
      loading,
      loadingStyle,
      stretch,
      buttonStyle,
      size,
      shadow,

      // DSExternalLink props
      children,
      className,
      ...anchorProps
    } = this.props

    return (
      <DSButtonBase
        appearDisabled={appearDisabled}
        disabled={disabled}
        loading={loading}
        stretch={stretch}
        buttonStyle={buttonStyle}
        size={size}
        shadow={shadow}
        loadingStyle={loadingStyle}
        children={baseProps => (
          <DSExternalLink
            {...anchorProps}
            className={classnames(baseProps.buttonClassNames, className)}
          >
            {baseProps.buttonContents(children)}
          </DSExternalLink>
        )}
      />
    )
  }
}

// props that external users of all DSButton varieties can set
// NB: if you add something here make sure to add it to all the varieties' destructured props declarations
interface IDSButtonBaseProps {
  /** whether the button looks disabled or not. It can still be clicked unless `disabled` is set */
  appearDisabled?: boolean
  /** Actually not clickable */
  disabled?: boolean
  loading?: boolean
  loadingStyle?: TLoadingStyle
  stretch?: boolean
  buttonStyle?: ButtonStyle
  size?: ButtonSize
  shadow?: boolean
}

// props that implementations of DSButtonBase pass to the Base, not set by external users of the concrete varieties
interface IDSButtonBaseInternalProps {
  children: (props: IDSButtonBaseRenderProps) => React.ReactNode
}

interface IDSButtonBaseRenderProps {
  buttonClassNames: string
  buttonContents: (children: React.ReactNode) => React.ReactNode
}

const DEFAULT_STYLE: ButtonStyle = 'none'

class DSButtonBase extends React.Component<
  IDSButtonBaseProps & IDSButtonBaseInternalProps
> {
  render() {
    const {
      appearDisabled,
      disabled,
      loading,
      loadingStyle,
      stretch,
      buttonStyle,
      size,
      shadow,
    } = this.props

    const style = buttonStyle === undefined ? DEFAULT_STYLE : buttonStyle
    const sizeToUse = size || 'none'

    const buttonClassNames = classnames({
      [styles['button-wrapper']]: true,
      [styles['button']]: !!style,
      [styles['loading']]: loading,
      [styles['stretch']]: stretch,
      [styles[style]]: !!style,
      [styles['disabled']]: appearDisabled || disabled,
      [styles[sizeToUse]]: !!sizeToUse,
      [styles['shadow']]: shadow,
    })

    const buttonContents = (childrenOfConcreteClass: React.ReactNode) => (
      <div>
        {loading &&
          (loadingStyle === 'dotDotDot' ? (
            <LoadingDots className={styles['loading-dotdotdot']} />
          ) : loadingStyle === 'spinner' ? (
            <LoadingSpinner darkBackground className={styles['loading-spinner']} />
          ) : null)}
        <div
          className={
            loading && loadingStyle !== 'none' ? styles['children-hidden'] : ''
          }
        >
          {childrenOfConcreteClass}
        </div>
      </div>
    )

    return this.props.children({
      buttonClassNames,
      buttonContents,
    })
  }
}
