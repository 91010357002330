import AdminNewUser from '~/api/endpoints/admin/AdminNewUser'

import SignIn from '~/api/endpoints/auth/SignIn'
import SignOut from '~/api/endpoints/auth/SignOut'
import GeneratePasswordResetCode from '~/api/endpoints/auth/GenerateResetPasswordCode'
import PasswordReset from '~/api/endpoints/auth/PasswordReset'

import GetInitialData from '~/api/endpoints/GetInitialData'
import GetDashboardData from '~/api/endpoints/dashboard/GetDashboardData'
import SignUp from './auth/SignUp'
import RegisterPerson from './weather/Register'
import GetPeopleData from './weather/GetPeopleData'
import EditPersonDetails from './weather/EditPeopleData'
import DeletePerson from './weather/DeletePerson'

import CreateMapping from './finance/CreateMapping'
import Convert from './finance/Convert'
import ListMappings from './finance/ListMappings'

const api = {
  admin: {
    newUser: new AdminNewUser(),
  },
  auth: {
    signUp: new SignUp(),
    signIn: new SignIn(),
    signOut: new SignOut(),
    generatePasswordResetCode: new GeneratePasswordResetCode(),
    passwordReset: new PasswordReset(),
  },
  globalData: new GetInitialData(),
  dashboard: new GetDashboardData(),

  // Weather!
  weather: {
    register: new RegisterPerson(),
    people: new GetPeopleData(),
    editDetails: new EditPersonDetails(),
    deletePerson: new DeletePerson(),
  },

  // Finance!
  finance: {
    createMapping: new CreateMapping(),
    convert: new Convert(),
    listMappings: new ListMappings(),
  },
}

export default api
