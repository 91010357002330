import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TListMappingsResponse} from '~/api/generated/types/CreateMapping'

export const ListMappingsMockResponse: TListMappingsResponse = {
  mappingResponse: [
    {
      mappingId: 'mapping-0',
      mappingName: 'mapping-0',
      mappingFields: [
        {
          mappingFieldInitialColumn: 'initial column 0',
          mappingFieldFinalColumn: 'final column 0',
        },
        {
          mappingFieldInitialColumn: 'initial column 1',
          mappingFieldFinalColumn: 'final column 1',
        },
      ],
    },
  ],
}

export default class ListMappings extends PostApi<{}, TListMappingsResponse> {
  protected getMockResponse(): TListMappingsResponse {
    return ListMappingsMockResponse
  }

  protected getPath(pathPieces: {organizationId: string}) {
    return serverEndpoints.financeListMappings(pathPieces)
  }

  protected transformResponse(r: TListMappingsResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
